<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <side-bar>
      <template slot="links">

        <sidebar-item :link="{ name: 'Dashboard', icon: 'fa fa-compass', path:'/', badge:inboxUnread}">
        </sidebar-item>

        <sidebar-item :link="{name: 'Marketplace', icon: 'fa fa-shopping-basket'}" >
            <sidebar-item  :link="{ name: 'Products', icon: 'fa fa-shopping-basket', path:'/marketplace'}"></sidebar-item>
            <sidebar-item  :link="{name: 'Production Contracts', icon: 'fa fa-list',path: '/production/contract'}"></sidebar-item>
            <sidebar-item  :link="{name: 'Product Bid', icon: 'fa fa-list',path: '/bid/request'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{name: 'Create Listing', icon: 'fa fa-plus'}" v-if="!isAdmin">
          <sidebar-item :link="{name: 'Product', icon: 'fa fa-plus', path:'/commodity/new'}"></sidebar-item> 
          <sidebar-item :link="{name: 'Production Contracts', icon: 'fa fa-plus',path:'/production/contract/new'}"></sidebar-item>
          <sidebar-item :link="{name: 'Post Bid', icon: 'fa fa-plus',path:'/bid/request/new'}"></sidebar-item>
        </sidebar-item>


        <sidebar-item v-if="!user.role.admin"
          :link="{ name: 'Negotiation Items', icon: 'fa fa-inbox', path:'/negotiation/items'}"
        ></sidebar-item>
     
        <!-- <sidebar-item v-if="isBuyer"
          :link="{ name: 'Grain Cart', icon: 'fa fa-shopping-cart', path:'/product/grain/cart'}"
        ></sidebar-item> -->
      

        <sidebar-item :link="{ name: 'COMPLETED ORDERS', icon: 'fa fa-truck'}">
          <sidebar-item :link="{ name: (!isAdmin) ? 'PRODUCT ORDERS' : 'Product Orders', path:'/orders'}"></sidebar-item>          
          <sidebar-item :link="{ name: (!isAdmin) ? 'PRODUCTION CONTRACTS' : 'Production contracts', path:'/production/contract/complete/list'}"></sidebar-item>
          <sidebar-item :link="{ name: (!isAdmin) ? 'PRODUCT BIDS' : 'Product Bids', path:'/bid/request/complete/list'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item
          v-if="isAdmin"
          :link="{ name: 'Settings', icon: 'fa fa-cog', path:'/settings'}"
        ></sidebar-item>


        <sidebar-item v-if="isAdmin" :link="{name: 'Search By', icon: 'fa fa-search'}">
          <sidebar-item v-if="isAdmin" :link="{ name: 'User', icon: 'fa fa-search', path:'/search/buyer'}"></sidebar-item>
          <sidebar-item v-if="isAdmin" :link="{ name: 'Purchase Order', icon: 'fa fa-search', path:'/search/purchase/order'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="isAdmin"
          :link="{ name: 'Messages', icon: 'fa fa-inbox', path:'/all-messages'}"
        ></sidebar-item>

        <sidebar-item v-if="isAdmin"
          :link="{name: 'Users', icon: 'fa fa-users',path: '/users'}"
        ></sidebar-item>

     

        <li><a target="_blank" href="https://www.ngf-global.com/contact-us" aria-current="page" class="nav-link"><i class="fa fa-question-circle"></i><p>Support</p></a></li>
        <user-menu></user-menu>

      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <b-spinner v-if="loading" />
          <router-view v-else></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script> 
import {mapState} from 'vuex';
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import Vue from "vue";
import {bus} from '@/vuex/bus.js';
import {db} from '@/firebase';
// import { messaging } from "@/firebase";
import store from '@/vuex/store.js';
import TopNavbar from "@/components/Layout/TopNavbar.vue";
import ContentFooter from "@/components/Layout/ContentFooter.vue";
import UserMenu from "@/components/Layout/UserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import { collectionData } from 'rxfire/firestore';
export default {
  components: {
    TopNavbar,
    ContentFooter,
    UserMenu,
    ZoomCenterTransition
  },
  computed:{
    ...mapState(['user','filters','productionContractFilters','subscriptions', 'bidRequestFilter'])
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },

    // Store fcm token in user store table
    // async updateFcmToken(){
    //   if(process.env.VUE_APP_FIREBASE_CONFIG_WEB_PUSH_NOTIFICATION != undefined){
    //     messaging.getToken({ vapidKey: process.env.VUE_APP_FIREBASE_CONFIG_WEB_PUSH_NOTIFICATION }).then(async(currentToken) => {
    //        if(currentToken){
    //             let ref = await db.collection("users").doc(this.user.uid);
    //             ref.update({ fcmToken: currentToken, webPushNotification: true });
    //         } else {
    //          console.log('No registration token available. Request permission to generate one.');
    //        }
    //    }).catch((err) => {
    //         console.log('An error occurred while retrieving token. ', err);
    //     });
    //   }
    // },

    async load(){
      let vm = this;
      vm.loading=true;
      
      //web push notification related code for generating devices token and store on user profile. 

      //Start
      // if('serviceWorker' in navigator){
      //   navigator.serviceWorker.register('/firebase-messaging-sw.js');
      // }
      // window.addEventListener('load', () => {
      //   navigator.serviceWorker.register('/firebase-messaging-sw.js');
      // });

      // navigator.serviceWorker.register('/firebase-messaging-sw.js');

      // messaging.requestPermission().then((res) => {
      //     console.log(res);
      //     vm.updateFcmToken();
      // }).catch(() => {
      //     console.log("user did't give permission");
      // });

      // const permission = await Notification.requestPermission();
      // if(permission === "denied"){
      //   console.error('The user explicity denied the permission request.')
      // } 
      // if(permission === "granted"){
      //   await vm.updateFcmToken();
      //   console.info('The user accepted the permission request.')
      // }
      //END

      if(vm.user.emailNotification === undefined){
        let ref = await db.collection("users").doc(vm.user.uid);
        ref.update({ emailNotification: true });
      }      

      // messaging.onMessage((payload) => {
      //   console.log(payload);
      // });

      if(vm.subscriptions.active&&vm.user.subscription&&vm.user.subscription.stripeSubscription&&vm.user.subscription.stripeSubscription.status!='trialing'&&vm.user.subscription.stripeSubscription.status!='active'){
        this.$router.replace('/account');
      }

      collectionData(db.collection('inbox').where('to','==',(vm.user.role.admin?'admin':vm.user.uid)).where('unread','==',true))
      .subscribe((m)=>{
        vm.inboxUnread=m.length;
      });

      let ref = await db.collection('commodity_categories').orderBy('name','asc').get();
      let categories = [];
      ref.forEach((c)=>categories.push({id:c.id,name:c.data().name,type:'category',checked:false,hidden:false}));

      // let ref = await db.collection('commodity_types').orderBy('name','asc').get();
      // let commodities = [];
      // ref.forEach((c)=>commodities.push({id:c.id,name:c.data().name,type:'commodity',checked:false,hidden:false}));

      ref = await db.collection('certifications').orderBy('name','asc').get();
      let certifications = [];
      ref.forEach((c)=>certifications.push({id:c.id,name:c.data().name,type:'certification',checked:false,hidden:false}));
      store.commit('updateFilters',{category:categories,certification:certifications,subtype:[],commodity:[] });

      ref = await db.collection('specifications').orderBy('name', 'asc').get();
      let specifications = [];
      ref.forEach((s)=>specifications.push({id:s.id,name:s.data().name,type:'specification',checked:false,hidden:false}))

      vm.isAdmin = vm.user.role.admin;
      vm.isProducer = vm.user.role.producer;
      vm.isBuyer = vm.user.role.buyer;

      store.commit('updateProductionContractFilters', {category:categories,specification:specifications,subtype:[],commodity:[] });
      store.commit('updateBidRequestFilters', {category:categories,specification:specifications,subtype:[],commodity:[] });
      bus.$on('filterChecked',async(filter)=>{
        let filters = store.state.filters;
        filters[filter.type].map((f,i)=>{
          let fU = {...f,checked:(filter.id==f.id?filter.checked:f.checked),hidden:(filter.checked?(f.id!=filter.id):false)};
          Vue.set(filters[filter.type],i,fU)
          return fU;
        });
        store.commit('updateFilters',filters);
      });

      bus.$on('productionContractFilterChecked', async(filter) => {
        let productionContractFilter = store.state.productionContractFilters;
        productionContractFilter[filter.type].map((f,i)=>{
          let fU = {...f,checked:(filter.id==f.id?filter.checked:f.checked),hidden:(filter.checked?(f.id!=filter.id):false)};
          Vue.set(productionContractFilter[filter.type],i,fU)
          return fU;
        });
        store.commit('updateProductionContractFilters',productionContractFilter);
      });

      bus.$on('bidRequestFilterChecked', async(filter) => {
        let bidRequestFilter = store.state.bidRequestFilters;
        bidRequestFilter[filter.type].map((f,i) =>{
          let fU = {...f,checked:(filter.id==f.id?filter.checked:f.checked),hidden:(filter.checked?(f.id!=filter.id):false)};
          Vue.set(bidRequestFilter[filter.type],i,fU)
          return fU;
        });
        store.commit('updateBidRequestFilters',bidRequestFilter);
      });

      vm.loading=false;
    }
  },
  data() {
    return {
      inboxUnread:0,
      loading:true,
      isAdmin: false,
      isBuyer: false,
      isProducer: true,
    };
  },
  watch:{
    user(){
      if(this.user.uid) this.load();
    }
  },
  async created(){
    if(this.user.uid) this.load();
  },
  mounted() {
    let docClasses = document.body.classList;
    let isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar("sidebar");
      initScrollbar("sidebar-wrapper");

      docClasses.add("perfect-scrollbar-on");
    } else {
      docClasses.add("perfect-scrollbar-off");
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}

@media screen and (max-width:992px)  {
  .mobile-badge{
    position: absolute;
    top: 14px;
    right: 10px;
  } 
}

</style>
