<template>
    <div>
        <ValidationObserver ref="form">
            <form @submit.prevent="validate">
                <div class="container">
                    <div class="row" v-if="$route.params.id">
                        <div class="d-block d-md-none col-6"><b-button @click="backTo" variant="primary"><i class="fa fa-chevron-left mr-2"></i> Back</b-button></div>
                        <div v-if="!saved" class="col-6 col-md-12 text-right">
                            <button @click="updateProductionContractStatus(productionContract.active)" type='button' :disabled="error" :class="'btn btn-'+(productionContract.active?'primary':'outline-primary')"><i :class="'fa fa-'+(productionContract.active?'toggle-on':'toggle-off')+' mr-2'"></i> ACTIVE</button>
                        </div>
                    </div>
                    
                    <div v-if="saved" class="row mt-4">
                        <div class="col d-none d-md-flex"></div>
                        <card  v-if="$route.params.id" class="card-lock text-center mt-4 pb-4" no-footer-line>
                            <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                            <h4 class="card-title mt-0 mb-4">Your listing is saved!</h4>
                            <p><a @click="backTo" class="text-info">Return</a></p>
                        </card>
                    </div>

                    <div v-else class="row">
                        <div class="col-12 col-md-6">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="m-0">General</h3>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                    name="productionContract.category"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                    >
                                        <b-form-group
                                            label="Category"
                                            :invalid-feedback="'Category is required'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-select @change="categoryChange" placeholder="Choose category" v-model="productionContract.category" :options="optionCategories" :state="(failed?false:(passed?true:null))"></b-form-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                    name="productionContract.year"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                    >
                                        <b-form-group
                                            label="Crop year"
                                            :invalid-feedback="'Year is required'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-select placeholder="Choose year" v-model="productionContract.year" :options="sortedYears" :state="(failed?false:(passed?true:null))"></b-form-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                    name="productionContract.type"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                    >
                                        <b-form-group
                                            label="Product"
                                            :invalid-feedback="'Product is required'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-select @change="typeChange" :disabled="optionTypes.length==0" placeholder="Choose product" v-model="productionContract.type" :options="optionTypes" :state="(failed?false:(passed?true:null))"></b-form-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                    name="productionContract.subtype"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                    >
                                    <b-form-group
                                            label="Type"
                                            :invalid-feedback="'Type is required'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-select @change="subTypeChange" :disabled="optionSubTypes.length==0" placeholder="Choose type" v-model="productionContract.subtype" :options="optionSubTypes" :state="(failed?false:(passed?true:null))"></b-form-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <b-form-group
                                    label="Variety"
                                    >
                                        <b-form-input placeholder="Enter variety" v-model="productionContract.variety" />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <ValidationProvider
                                        name="productionContract.act_of_god"
                                        rules="required"
                                        v-slot="{ passed, failed }"
                                    >
                                        <b-form-group
                                            label="Act of God"
                                            :invalid-feedback="'Please select'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-radio-group v-model="productionContract.act_of_god">
                                                <b-form-radio value="yes">Yes</b-form-radio>
                                                <b-form-radio value="no">No</b-form-radio>
                                            </b-form-radio-group>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <ValidationProvider
                                        name="productionContract.available_acres"
                                        rules="required|decimal"
                                        v-slot="{ passed, failed }"
                                    >
                                        <b-form-group label="Number of Acres"
                                            :invalid-feedback="'Please enter a valid number'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-input placeholder="Enter request number of acres" v-model="productionContract.available_acres" :state="(failed?false:(passed?true:null))" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <ValidationProvider
                                        name="productionContract.unit"
                                        rules="required"
                                        v-slot="{ passed, failed }"
                                    >
                                    <b-form-group
                                        label="Unit of Measure"
                                        :invalid-feedback="'Unit is required'"
                                        :state="(failed?false:(passed?true:null))"
                                    >
                                        <b-form-select placeholder="Choose units" v-model="productionContract.unit" :options="optionUnits" :state="(failed?false:(passed?true:null))"></b-form-select>
                                    </b-form-group>

                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                        name="productionContract.total"
                                        rules="required|decimal"
                                        v-slot="{ passed, failed }"
                                    >
                                        <b-form-group
                                            :label="'Guaranteed'+(selectedUnit?' '+selectedUnit + ' Per Acre':'')"
                                            :invalid-feedback="'Please enter a valid number'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-input placeholder="Enter total" v-model="productionContract.total" :state="(failed?false:(passed?true:null))"></b-form-input>
                                            <div class="invalid-feedback d-block" v-if="minimumFailed">Total must meet the minimum of {{numeral(minimumQuantity).format('0,000')}} {{selectedUnit}}</div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                        name="productionContract.priceOption"
                                        rules="required"
                                        v-slot="{passed, failed}"
                                    > 
                                        <b-form-group
                                            label="Price Option"
                                            :invalid-feedback="'Price Option is required'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-select placeholder="Choose Price Options" v-model="productionContract.priceOption" :options="optionPriceOption" :state="(failed?false:(passed?true:null))"></b-form-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                        name="CurrencyPrice"
                                        :rules="{ required : true}"   
                                        v-slot="{ passed, failed }"
                                    >
                                        <b-form-group
                                            :label=" (selectedPriceOption? selectedPriceOption : '') +' Price'+(selectedUnit?' per '+selectedUnit:'')"
                                            :invalid-feedback="'Please enter a valid number'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                        <b-form-input v-currency="priceFormatOptions" ref="currencyInput" placeholder="Enter price" v-model="CurrencyPrice" :state="(failed?false:(passed?true:null))"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div>
                                <span>Delivery Window</span>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                    name="productionContract.startDate"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                    >
                                    <b-form-group
                                        :invalid-feedback="'Start Date is required'"
                                        :state="(failed?false:(passed?true:null))"
                                    >
                                        <Datepicker placeholder="Start Date" v-model="productionContract.startDate"   @selected="selectStartDate" :disabledDates="disabledStartDates" :state="(failed?false:(passed?true:null))"/>
                                    </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                        name="productionContract.endDate"
                                        :rules="{required: true, minimumStartDate: productionContract.startDate }"
                                        v-slot="{ passed, failed, errors }"
                                    >
                                        <b-form-group
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <Datepicker placeholder="End Date" v-model="productionContract.endDate" :disabled="disabledEndDate" :disabledDates="disabledEndDates" :state="(failed?false:(passed?true:null))"/>
                                        </b-form-group>
                                        <b-form-invalid-feedback :state="(failed?false:(passed?true:null))">
                                            {{ errors[0]=='MinimumStartDate'?'End date must be after the start date ' + moment(productionContract.startDate).format('ll'):'End Date is required'}}
                                        </b-form-invalid-feedback>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2"><hr /></div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <h5>Details</h5>
                                </div>
                            </div>
                            <div class="col-12 pt-2 bg-white border rounded">
                                <b-form-group
                                    id="fieldset-commodity-details"
                                    description="Enter any important details for your crop."
                                    label="Include more details"
                                >
                                    <vue-editor v-model="productionContract.details"></vue-editor>
                                </b-form-group>
                            </div>
                            <!-- <div class="row">
                                <div class="col-12 pt-4 text-center">
                                    <p><a @click="showCreatingGreat=true" class="text-success font-weight-bold"><i class="fa fa-info-circle mr-2" />Learn more about creating great product listings</a></p>
                                </div>
                            </div> -->
                            <b-modal size="lg" ref="learn-more-modal" v-model="showCreatingGreat" hide-footer title="Creating Great Product Listings">
                                <CreatingGreatListings />
                            </b-modal>
                            <div class="row">
                                <div class="col-12 pt-2"><hr /></div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <h5>Location</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <b-alert show variant="info">The requested address will be used for shipping purposes and the precise address will not be shared with the Buyer; however, the general address location will be shared.</b-alert>
                                    <b-form-group
                                        label="Where is your Production Contract located?"
                                    >
                                        <gmap-autocomplete
                                            @place_changed="setPlace"
                                            :types="['address']"
                                            :enable-geolocation="true"
                                        >
                                        </gmap-autocomplete>
                                        <div class="invalid-feedback d-block" v-if="positionFailed">Please set the location</div>
                                    </b-form-group>
                                    <b-button variant="info" @click="setPosition">Set Location</b-button>
                                    <gmap-map
                                        v-if="productionContractPlace"
                                        :center="productionContractPlace.position"
                                        :zoom="12"
                                        class="mt-4"
                                        style="width:100%;  height: 320px;"
                                    >
                                        <gmap-marker
                                            :position="productionContractPlace.position"
                                        ></gmap-marker>
                                    </gmap-map>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2"><hr /></div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2"><h5>Certifications</h5></div>
                            </div>
                            <EditSpecification v-for="specification in productionContract.specifications" :key="specification.id" :specification="specification" @cert-add="getAddCert" @cert-del="getDelCert"  @remove="certRemoved" />
                            <NewSpecification :productionContractID="productionContract.id" :certificationIndex="productionContract.specifications.length" @saved="certAdded" @cert-add="getAddCert" @cert-del="getDelCert" class="mt-4" />
                            <div v-if="error" class="row mt-2">
                                <div class="col-12"><div class="alert alert-danger"><i class="fa fa-exclamation-triangle mr-2"></i>One or more required fields are missing.</div></div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12"><div class="alert alert-primary"><i class="fa fa-balance-scale mr-2"></i>By proceeding, you acknowledge reading and agreeing to NGF-Global’s policies <a href="https://www.ngf-global.com/policy" target="_blank" class="text-white font-weight-bold"><u>https://www.ngf-global.com/policy</u></a></div></div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-6">
                                    <b-button @click="backTo" variant="light">Cancel</b-button>
                                </div>
                                <div class="col-6 text-right">
                                    <b-button :disabled="saving" @click="validate" variant="success"><i v-if="saving" class="fa fa-spinner mr-2"></i><i v-else class="fa fa-save mr-2"></i>Submit<i class="fa fa-arrow-right ml-2" /></b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>

import Vue from "vue";
import {mapState} from 'vuex'
import {db} from '@/firebase';
import moment from 'moment';
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import Datepicker from 'vuejs-datepicker';
import { VueEditor } from "vue2-editor";
import CreatingGreatListings from "@/views/Dashboard/Commodity/components/CreatingGreatListings.vue";
import * as VueGoogleMaps from "vue2-google-maps";
import * as geofire from 'geofire-common';
import NewSpecification from "@/components/ProductionSpecification/New.vue";
import EditSpecification from "@/components/ProductionSpecification/Edit.vue";
import firebase from 'firebase';
import { getValue, setValue } from "vue-currency-input";
import {bus} from '@/vuex/bus.js';



extend("required", required);
extend("decimal",n=>!isNaN(parseFloat(n)) && isFinite(n));
extend("minimumPrice", {
    params: ["minimumPrice"],
      validate: (value, {minimumPrice})=>{
        if(typeof value != 'number'){
          let tempPrice = value.replace('$', '');
          return (parseFloat(tempPrice)>=minimumPrice);
        }  else {
            return (parseFloat(value)>=minimumPrice);
        }
      },
      message:'Minimum'
});
extend("minimumStartDate", {
  params: ["minimumStartDate"],
  validate: (value, {minimumStartDate})=>{
    const startDate = new Date(value);
    const EndDate = new Date(minimumStartDate);
    return !(startDate.getTime()<=EndDate.getTime());
  },
  message:'MinimumStartDate'
});

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_FIREBASE_CONFIG_API_KEY,
    libraries: "places"
  }
});

export default {
    computed: {
        ...mapState(['user']),
        selectedUnit(){
            let unit = '';
            this.optionUnits.forEach((u)=>{
                if(u.value==this.productionContract.unit) unit = u.text;
            })
            return unit;
        },
        selectedPriceOption(){
            let priceOption = '';
            this.optionPriceOption.forEach((p) => {
                if(p.value==this.productionContract.priceOption) priceOption = p.text;
            });
            return priceOption;
        },
        availableYears(){
          return [...Array(6).keys()].map(k=>Math.abs(k-moment().year()));
        },
        sortedYears: function(){
            return [...this.years].sort((a, b) => {
                return a - b;
            });
        },
    },
    watch:{
    //     CurrencyPrice(){
    //         console.log(this.$refs.currencyInput);
    //         this.productionContract.price = getValue(this.$refs.currencyInput);
    //     }
    },
    components:{
        VueEditor,
        CreatingGreatListings,
        Datepicker,
        EditSpecification,
        NewSpecification
    },
    data() {
        return{
            error:false,
            saving:false,
            saved: false,
            productionContract: {
                id:null,
                available_acres:null,
                details:null,
                location:null,
                specifications:[],
                unit:null,
                priceOption: null,
                price:null,
                total:null,
                startDate: null,
                type: null,
                subtype: null,
                variety:null,
                category: null,
                year: null,
                endDate: null,
                commodityId: null,
                active:true,
                is_delete: 0,
                status: true,
                grainSampleMissing: null,
                act_of_god: "yes",
                slug: null,
            },
            CurrencyPrice: null,
            optionUnits:[],
            optionPriceOption: [],
            optionCategories:[],
            optionTypes:[],
            optionSubTypes:[],
            years: [],
            showCreatingGreat:false,
            positionFailed: null,
            place:null,
            productionContractStatus: null,
            productionContractPlace:null,
            specification_name:false,
            grainSampleMissing:null,
            minimumFailed: null,
            disabledStartDates: {
                to: new Date(Date.now() - 8640000)
            },
            disabledEndDate: true,
            disabledEndDates: {
                to: new Date(Date.now() - 8640000)
            },
            priceFormatOptions: {
                locale:"en",
                currency: "USD",
                precision: 2,
                distractionFree: false,
                valueAsInteger: false,
                autoDecimalMode: true,
                allowNegative: true
            },
        }
    },

    async created(){
        const vm = this;
        if(vm.$route.params.id){
            let cRef = await db.collection('production_contract').doc(vm.$route.params.id).get();
            if((vm.user.uid != cRef.data().userID)&& (!vm.user.role.admin)) {
                vm.$router.push("/permission/denied/productionContract");
                bus.$emit('prepareTitle', null);
                return;
            }  
        }
    },

    async mounted(){
        let vm = this;
        bus.$emit('prepareTitle',null);
        await vm.load();
    },
    methods:{

        async load(){
            const vm = this;
            await vm.getYears();
            await vm.loadProductionContract();
            await vm.loadOptions();
        },

        async loadProductionContract(){
            const vm = this;
            let cRef = await db.collection('production_contract').doc(vm.$route.params.id).get();
            if(cRef.exists){
                let data = cRef.data();

                for(let key in data){
                    if(key in vm.productionContract) vm.productionContract[key]=data[key];
                }

                vm.CurrencyPrice = data.price;
                vm.slug = data.slug;
                vm.productionContract.startDate = data.startDate.toDate();
                vm.productionContract.endDate = data.endDate.toDate();
                vm.disabledEndDate = false;
                vm.productionContractStatus = (vm.productionContract.active) ? "active" : "inactive"
                
                await vm.categoryChange();
                await vm.typeChange();
                
                cRef = await db.collection('production_contract_location').doc(vm.$route.params.id).get();

                if(cRef.exists){
                    vm.productionContractPlace = {...cRef.data()};
                }
                
                setValue(this.$refs.currencyInput, data.price)
                
                if(data.slug == undefined){
                    vm.subTypeChange();
                } 
            } 
        },

        getAddCert: async function getAddCert(value){ 
            const vm = this;
            let tempCertificationArray = await vm.getUniqueArray(vm.productionContract.specifications);
            vm.productionContract.specifications = [];
            vm.productionContract.specifications = tempCertificationArray;
            vm.specification_name = (value === "Standard Grade Test" || value === "I1fDAEKhTwpOUqNd41RA" || value === "ZxTMNwcDS13tgIpYXxcw" ) ? true : vm.specification_name;
            vm.specification_name = (value === "Pesticide Test and/or Herbicide Test" || value === "AC4ISjtmNDKq62MqlVCR" || value === "rJN8HDaXrlEyUgYu0REl" ) ? true : vm.specification_name;
            vm.specification_name = (value === "Organic Certification" || value === "M5pvkZh2dEuXyuKkcR9D" || value === "Q04rVQ0vbu8RRvXGMuvT") ? true : vm.specification_name;
        },
        getDelCert: function getDelCert(value){ 
            const vm = this;
            vm.specification_name = (value === "Standard Grade Test" || value === "I1fDAEKhTwpOUqNd41RA" || value === "ZxTMNwcDS13tgIpYXxcw" ) ? true : vm.specification_name;
            vm.specification_name = (value === "Pesticide Test and/or Herbicide Test" || value === "AC4ISjtmNDKq62MqlVCR" || value === "rJN8HDaXrlEyUgYu0REl" ) ? true : vm.specification_name;
            vm.specification_name = (value === "Organic Certification" || value === "M5pvkZh2dEuXyuKkcR9D" || value === "Q04rVQ0vbu8RRvXGMuvT") ? true : vm.specification_name;
        },
        certRemoved(id){
            const vm = this;
            vm.productionContract.specifications=vm.productionContract.specifications.filter(c=>c.id!=id);
        },
        certAdded(cert, index, isCertificationNotDelete = true){
            const vm = this;
            if(isCertificationNotDelete){
                vm.productionContract.specifications[index] = cert;
            } else{
                vm.productionContract.specifications.splice(index, 1);
            }
        },
        randomNumberGenerate(){
            return Math.random() * (120 - 100) + 100;
        }, 
        setPlace(place) {
            const vm = this;
            let generateRandomNumber = (parseFloat("0.0000" + parseInt(vm.randomNumberGenerate())));

            let arr = place.address_components.filter((ac)=>{
                return (ac.types.indexOf('administrative_area_level_2')>-1 || ac.types.indexOf('administrative_area_level_1')>-1);
            });

            let address = arr.map(a=>a.long_name).join(', ');
            vm.place = {
                    position:{
                        lat: place.geometry.location.lat() + generateRandomNumber,
                        lng: place.geometry.location.lng()
                    },
                formatted_address:place.formatted_address,
                address:address,
                url:place.url,
                reference:place.reference
            };
        },
        setPosition() {
            const vm = this;
            if (vm.place) {
                vm.productionContractPlace = vm.place;
                const lat = vm.place.position.lat;
                const lng = vm.place.position.lng;
                const hash = geofire.geohashForLocation([lat, lng]);
                vm.productionContractPlace.geohash = hash;
                vm.productionContract.geohash = hash;
                vm.productionContract.placePosition = {
                    lat: lat,
                    lng: lng
                },
                vm.positionFailed=null;
            }else{
                vm.positionFailed=true;
            }
        },
     
        async categoryChange(){
            let vm = this;
            vm.optionTypes=[];
            vm.optionSubTypes=[];
            if(vm.productionContract.category){
                let types = await db.collection('commodity_types').where('category','==',vm.productionContract.category).get();
                if(!types.empty) types.forEach(t=>{vm.optionTypes.push({text:t.data().name,value:t.id})});
            }
        },

        async typeChange(){
            let vm = this;
            vm.optionSubTypes=[];
            if(vm.productionContract.type){
                let subtypes = await db.collection('commodity_subtypes').where('type','==',vm.productionContract.type).get();
                if(!subtypes.empty) subtypes.forEach(t=>{vm.optionSubTypes.push({text:t.data().name,value:t.id})});
            }
        },

        generateRandomNumber(){
            return Math.floor(Math.random() * (99 - 10 + 1) + 10) ;
        }, 

        async subTypeChange(){
            const vm = this;
            let tempSlug = null;

            let ref = await db.collection('commodity_subtypes').doc(vm.productionContract.subtype).get();
            if(!ref.empty) var subType = {...ref.data()}.name;

            ref = await db.collection('commodity_types').doc(vm.productionContract.type).get();
            if(!ref.empty) var type = {...ref.data()}.name;

            let productionContractRef = await db.collection('production_contract')
                        .where('type', '==', vm.productionContract.type)
                        .where('subtype', '==', vm.productionContract.subtype).get();

            if(!productionContractRef.empty){
                let totalExistingProduct = productionContractRef.size + vm.generateRandomNumber();
                tempSlug = (subType + "-" + type + "-" + totalExistingProduct).trim();
            } else {
                tempSlug = (subType + "-" + type).trim();
            }                      
            
            let productSlug = tempSlug.toLowerCase().replace(/\s+/g,'-');
            productSlug = productSlug.replace("/", "-");
            vm.productionContract.slug = productSlug;
        },


        async validate() {
            const vm = this;
            vm.error=false;
            vm.saving=true;
            vm.productionContract.price = getValue(vm.$refs.currencyInput);

            let res = await vm.$refs.form.validate();
            if(!vm.productionContractPlace) vm.positionFailed = true;
            
            if(res&&!vm.positionFailed){
                let data = vm.productionContract;
                data.active = ((vm.productionContractStatus == "active" ? true : false))

                if(data.specifications.length==0) {
                    data.specification = firebase.firestore.FieldValue.delete();
                    data.specifications = firebase.firestore.FieldValue.delete();
                } else {
                    data.specification = data.specifications.map(c=>c.specification);
                }

                data.location=vm.productionContractPlace.address;
                try{
                    data.updated = firebase.firestore.FieldValue.serverTimestamp();
                    await db.collection('production_contract').doc(vm.productionContract.id).update(data).then(async() => {
                        await db.collection('production_contract_location').doc(vm.productionContract.id).set({...vm.productionContractPlace});
                    }).catch((e) => {
                        vm.error=e;
                    });
                    
                } catch(e){
                    vm.error=e;
                }
                vm.saving=false;    
                vm.saved=true;
            } else{
                vm.error=true;
                vm.saving=false;
            }
        },

       
        async updateProductionContractStatus(productionContractActiveStatus){
            const vm = this
            vm.productionContractStatus = (productionContractActiveStatus == true) ? 'inactive' : 'active' ; 
            if(confirm('Are you sure you want to make vm production contract ' + vm.productionContractStatus + '?')){
                let productionContractRef = await db.collection('production_contract').doc(vm.productionContract.id);
                productionContractRef.update({active: !productionContractActiveStatus }).then(() =>{
                    vm.productionContract.active = !productionContractActiveStatus
                });
            }
        },

        async getYears(){
            let refs = await db.collection('cropyear').orderBy('year', 'desc').get();
            refs.forEach(ref => {
                this.years.push(ref.data().year)
            });
        },

        async loadOptions(){
            const vm = this;
            let categories = await db.collection('commodity_categories').orderBy('name','asc').get();
            categories.forEach(c=>{vm.optionCategories.push({text:c.data().name,value:c.id})});

            let priceOptions = await db.collection('price_options').orderBy('name', 'asc').get();
            priceOptions.forEach(c=>{vm.optionPriceOption.push({text:c.data().name, value:c.id})});
        
            let units = await db.collection('units').orderBy('name','asc').get();
            units.forEach(c=>{vm.optionUnits.push({minimum:c.data().minimum,text:c.data().name,value:c.id})});
        },

        selectStartDate(){
            let vm = this;
            vm.disabledEndDate = false;
        },

        backTo(){
            bus.$emit('backToTop');
        },
        
        getUniqueArray(items){      
            return [
                ...new Map(
                    items.map((item) => [item['id'], item])
                ).values()
            ]
        },

    }
}
</script>