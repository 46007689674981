<template>
    <div class="container">
        <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </card>

            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else class="row">
            <div class="col-12 pr-0 pl-0">
                <b-card>
                    <b-card-header>
                        <div  class="d-flex align-items-center p-2 header-flex justify-content-between">
                            <h5 class="m-0 text-center">My Product Negotiation</h5>
                            <!-- <b-button class="m-0 btn float-left" variant="outline-light" @click="myDashboard" size="sm"><i class="fa fa-arrow-left mr-2"></i>Back</b-button> -->
                            <!-- <b-button v-if="!user.role.admin&&!user.role.producer" class="m-0 btn btn-danger float-right" :disabled="moveToGrainCartButton" variant="danger" @click="moveToGrainCart" size="sm">
                                Move To Grain Cart
                            </b-button> -->
                            <div v-if="sortedProductNegotiationItems.length > 0" class="d-flex flex-wrap justify-content-between">
                                <b-button v-if="!user.role.admin" class="ml-2 btn btn-primary float-right" variant="info" @click="updateNegotiations(true)" size="sm">
                                    Accept
                                </b-button>
                                <b-button v-if="!user.role.admin" class="ml-2 btn btn-primary float-right" variant="danger" @click="updateNegotiations(false)" size="sm">
                                    Decline
                                </b-button>
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body class="px-0">
                        <div v-if="successMessage">
                            <b-alert show variant="success">{{ successMessage }}</b-alert>
                        </div>
                         <div v-if="errors.length > 0">
                            <b-alert v-for="(error, index) in errors" :key="index" show variant="danger"><small>{{error}}</small></b-alert>
                        </div>
                        <template v-if="sortedProductNegotiationItems.length > 0">
                            <b-list-group>
                                <b-list-group-item v-for="(product, index) in getUniqueValueFromArray(sortedProductNegotiationItems)" :key="index" class="d-block d-md-flex align-items-center add-pointer">
                                    <b-checkbox size="is-large" :value="product" v-model="selectProductsNegotiation" :disabled="product.is_product_negotiation_status != undefined || product.commodity.userID !== user.uid">
                                    </b-checkbox>
                                    <div class="position-relative  activity-image" @click="openProductNegotiation(product.id)">
                                        <b-img class="d-none d-md-block img-dashboard-large-preview" v-if="product.commodity && product.commodity.images" :src="primaryImage(product.commodity.images).thumbURL" thumbnail />
                                        <b-img class="d-block d-md-none" v-if="product.commodity && product.commodity.images" :src="primaryImage(product.commodity.images).largeURL" thumbnail />
                                    </div>
                                    <div class="text-center text-md-left w-100 pl-0 pl-md-4 pr-0 pr-md-4" @click="openProductNegotiation(product.id)">
                                        <h6 class="mt-2 m-md-0">
                                            {{product.commodity.subtype.name}} {{product.commodity.type.name}}
                                        </h6>
                                        <div class="text-muted">
                                            <small>Purchase of {{numeral(product.amount).format('0,000')}} {{product.commodity.unit.name}} </small> 
                                        </div>
                                        <div class="text-muted">
                                            <small>{{product.commodity.subtype.name}} {{product.commodity.type.name}}</small>
                                        </div>
                                        <div class="text-muted">
                                            <small>Total: ${{numeral(product.total).format('0,000.00')}}</small>
                                        </div>
                                        <div class="text-muted" v-if="product.paymentOption">
                                            <small v-if="product.paymentOption == '100_at_purchase'">Payment Negotiation Request: 100% at time of Purchase</small>
                                            <small v-else-if="product.paymentOption == '50_at_purchase'">Payment Negotiation Request: Buyer and Seller Negotiated Payment Split</small>
                                            <small v-else-if="product.paymentOption == '100_at_delivery'">Payment Negotiation Request: 100% at time of Delivery</small>     
                                        </div>
                                        <div class="text-muted" v-if="product.is_product_negotiation_status != undefined && user && product.commodity.userID !== user.uid">
                                            <b-badge pill variant="danger" v-if="product.is_product_negotiation_status == 0">Rejected</b-badge>
                                            <b-badge pill variant="success" v-else>Approved</b-badge>
                                        </div>
                                        <div class="text-muted" v-if="product.is_product_negotiation_status == undefined && user && product.commodity.userID !== user.uid">
                                            <b-badge pill variant="warning" >Pending Approval</b-badge>
                                        </div>
                                    </div>
                                    <div class="text-right mt-2 mt-md-0" v-if="user && product.commodity.userID !== user.uid">
                                        <small>{{formatDate(product.created)}}</small>
                                        <b-button variant="success" @click="editPaymentOption(product)" :disabled="product.is_product_negotiation_status == 1 || editButton" size="sm">Edit</b-button>
                                        <b-button variant="danger" size="sm" :disabled="removeButton" @click.stop="removeProductFromNegotiation(product)">Remove</b-button>
                                    </div>
                                    <div class="text-right mt-2 mt-md-0" v-else>
                                        <div  v-if="product.is_product_negotiation_status == undefined" >
                                            <small>{{formatDate(product.created)}}</small>
                                            <div class="d-flex flex-row justify-content-between">
                                                <b-button variant="primary" size="md" @click="updateNegotiationStatus(true, product)" >Accept</b-button>
                                                <b-button variant="danger" size="md" @click="updateNegotiationStatus(false, product)" class="ml-2">Decline</b-button>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="text-muted" v-if="product.is_product_negotiation_status != undefined">
                                                <b-badge pill variant="danger" v-if="product.is_product_negotiation_status == 0">Rejected</b-badge>
                                                <b-badge pill variant="success" v-else>Approved</b-badge>
                                                <b-button variant="danger" size="sm" :disabled="removeButton" @click.stop="removeProductFromNegotiation(product)">Remove</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </b-list-group-item>
                            </b-list-group>
                        </template>
                        <template v-else>
                            <b>No Product found for Products Negotiation</b>
                        </template>
                    </b-card-body>
                </b-card>
            </div>
        </div>
        <div>
            <form ref="acceptForm" @submit.prevent="confirmProductNegotiation">
                <b-modal ref="model" size="lg" 
                    title="Confirmation of Negotiation" centered  v-model="confirmationNegotiationPopup" 
                    @show="resetConfirmationModel" 
                    @hide="resetConfirmationModel"
                >
                    <div v-if="confirmNegotiationLoading" class="row mt-4">
                        <div class="col d-none d-md-flex"></div>
                        <div class="col-12 col-md-8 col-xl-6 pt-4">
                            <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                            <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                            </card>
                        </div>
                        <div class="col d-none d-md-flex"></div>
                    </div>
                    <div v-else>
                        <p>
                            By entering your password, you acknowledge and consent to enter into a legally binding contract with the Buyer, pursuant to the NGF Global <a href="https://www.ngf-global.com/user-agreement" target="_blank">User Agreement</a>, for the purchase of the product listed.
                        </p>
                        <p v-if="confirmationPopupError" class="alert alert-danger mb-4">{{confirmationPopupError}}</p>
                        <ValidationProvider
                            name="confirmationPassword"
                            rules="required|min:6"
                            v-slot="{ passed, failed, errors }"
                            >
                            <b-form-group
                                :invalid-feedback="errors.length > 0 ? errors[0] : null"
                                :state="failed ? false : passed ? true : null"
                        >
                                <b-form-input
                                type="password"
                                placeholder="Please Enter Password"
                                v-model="confirmationPassword"
                                :class="confirmationPopupError ? 'is-invalid' : ''"
                                :state="failed ? false : passed ? true : null"
                                />
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <template #modal-footer="{ok,cancel}">
                        <b-button size="xl" :disabled="confirmNegotiationLoading" @click="cancel()">
                            Cancel
                        </b-button>
                        <b-button size="xl" :disabled="confirmNegotiationLoading" variant="success" @click="confirmProductNegotiation">
                            Continue
                        </b-button>
                    </template>
                </b-modal>
            </form>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase';
import { db } from '@/firebase';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { collectionData, docData } from 'rxfire/firestore';
import { mapState } from 'vuex'

import Vue from "vue";
import numeral from 'numeral';
import moment from 'moment';
import store from "@/vuex/store.js";
import {auth} from '@/firebase';

Vue.prototype.moment = moment;
Vue.prototype.numeral = numeral;

export default{
    computed:{
        ...mapState(['user']),
        sortedProductNegotiationItems: function(){
            return [...this.myNegotiationProducts].sort((a, b) => {
                return b.created.toDate() - a.created.toDate();
            });
        }
    },
    data(){
        return {
            loading: false,
            myNegotiationProducts: [],
            selectProductsNegotiation:[],
            errors: [],
            successMessage: '' ,
            moveToGrainCartButton: false,
            editButton: false,
            removeButton: false,
            confirmationNegotiationPopup: false,
            confirmationPopupError: null,
            confirmationPassword: null,
            confirmNegotiationLoading: false,
            clickUpdateNegotiationList: false,
            updateStatus: null,
            acceptProductNegotiation: null
        }
    },

    async mounted() {
        const vm = this;
        await vm.getMyNegotiationProducts();
    },
    methods: {
        async getMyNegotiationProducts(){
            const vm = this;
            vm.loading = true;
            vm.myNegotiationProducts = [];
            let ref = await db.collection("products_negotiation");
                collectionData(ref, 'id').pipe(
                    switchMap((orders) => {
                        if(orders.empty) return of([]);
                        return combineLatest(...orders.map((order) => {
                            return docData(db.collection('commodities').doc(order.commodityID)).pipe(
                                    map(c=>{
                                        if(JSON.stringify(c) !== '{}'){
                                            return {...order, commodity: c}
                                        } else {
                                            return of({});
                                        }
                                        
                                    })
                                )
                            })
                        )
                    })
                ).pipe(
                    switchMap((orders) => {
                        if(orders.empty) return of([]);
                        return combineLatest(...orders.map((order) => {
                            return docData(db.collection('commodity_types').doc(order.commodity.type)).pipe(
                                    map(c=>{
                                        if(JSON.stringify(c) !== '{}'){
                                            return {...order, commodity:{...order.commodity, type: c}}
                                        } else {
                                            return of({});
                                        }
                                        
                                    })
                                )
                            })
                        )
                    })
                ).pipe(
                    switchMap((orders) => {
                        if(orders.empty) return of([]);
                        return combineLatest(...orders.map((order) => {
                            return docData(db.collection('commodity_subtypes').doc(order.commodity.subtype)).pipe(
                                    map(c=>{
                                        if(JSON.stringify(c) !== '{}'){
                                            return {...order, commodity:{...order.commodity, subtype: c}}
                                        } else {
                                            return of({});
                                        }
                                    })
                                )
                            })
                        )
                    })
                ).pipe(
                    switchMap((orders) => {
                        if(orders.empty) return of([]);
                        return combineLatest(...orders.map((order) => {
                            return docData(db.collection('units').doc(order.commodity.unit)).pipe(
                                    map(c=>{
                                        if(JSON.stringify(c) !== '{}'){
                                            return {...order, commodity:{...order.commodity, unit: c}}
                                        } else {
                                            return of({});
                                        }
                                    })
                                )
                            })
                        )
                    })
                ).subscribe((products) => {
                    if(products.length != 0){
                        products.map((item) => {
                            if(item.commodity.userID === vm.user.uid && item.producerID === vm.user.uid){
                                vm.myNegotiationProducts.push(item);
                            } else if(item.commodity.userID !== vm.user.uid && item.buyerID === vm.user.uid){
                                vm.myNegotiationProducts.push(item);
                            }
                        });
                    }
                });
            setTimeout(() => {
                vm.loading = false;                    
            }, 5000);
        },


        moveToGrainCart(){
            const vm = this;
            vm.errors = [];
            vm.successMessage = '';
            if(vm.selectProductsNegotiation.length > 0){
                vm.loading = true;
                Array.from(Array(vm.selectProductsNegotiation.length).keys()).map(async (x) => {
                    let order = vm.selectProductsNegotiation[x];
                    if(order.commodity.active == false){
                        let error = order.commodity.subtype.name +" "+ order.commodity.type.name + "is currently inactive.";
                        vm.errors.push(error);
                        vm.selectProductsNegotiation = [];
                    } else if(order.is_product_negotiation_status == undefined){
                        let error  = "Payment negotiation request must be agreed upon by Producer."
                        vm.errors.push(error);
                    } else if(order.is_product_negotiation_status == 0){
                        let error = order.commodity.subtype.name +" "+ order.commodity.type.name + "payment negotiation request was rejected by the producer.";
                        vm.errors.push(error);
                        vm.selectProductsNegotiation = [];
                    } else {
                        order.created = firebase.firestore.FieldValue.serverTimestamp();
                        order.updated = firebase.firestore.FieldValue.serverTimestamp();
                        order.expired = firebase.firestore.Timestamp.fromDate(new Date(moment().add(48, 'hours')));
                        order.purchased = false;
                        delete order.is_product_negotiation_status;
                        await vm.deleteProductNegotiation(order.id).then(async () => {
                            await db.collection('grain_cart').add(order);
                            vm.selectProductsNegotiation = [];
                            vm.successMessage = "Your Product has Moved to the Grain Cart.";
                        })
                    }
                })
                setTimeout(() => {
                    vm.loading = false;       
                    vm.myNegotiationProducts = [];
                    vm.getMyNegotiationProducts();         
                }, 4000);
            } else {
                vm.loading = false;
                let error = "Please select product a to be moved to grain cart.";
                vm.errors.push(error);
            }
            setTimeout(() => {
                vm.successMessage = '';
                vm.errors = [];
            }, store.state.timeout.time);
        },

        async updateNegotiationStatus(negotiationStatus, productNegotiation) {
            const vm = this;
            vm.acceptProductNegotiation = null;
            if(negotiationStatus){
                    vm.acceptProductNegotiation = productNegotiation;
                    vm.confirmationNegotiationPopup = true;
            } else {
                if(confirm('Are you sure want to decline payment request?')){
                    let ref = db.collection('products_negotiation').doc(productNegotiation.id);
                    ref.update({is_product_negotiation_status: 0, is_hide_paymentOption: 1, negotiation_id: productNegotiation.id}).then(() => {
                        // vm.addUpdateStatusInsertMessage("decline", productNegotiation);
                    });
                }
            }
        },

        async updateNegotiations(negotiationStatus){
            const vm = this;
            vm.errors = [];
            vm.successMessage = '';
            if(vm.selectProductsNegotiation.length > 0){
                if(negotiationStatus){
                    vm.clickUpdateNegotiationList = true;
                    vm.confirmationNegotiationPopup = true;
                } else {
                    if(confirm('Are you sure want to decline payment request?')){
                        vm.loading = true;
                        Array.from(Array(vm.selectProductsNegotiation.length).keys()).map(async(x) => {
                            let ref = db.collection('products_negotiation').doc(vm.selectProductsNegotiation[x].id);
                            ref.update({is_product_negotiation_status: 0, is_hide_paymentOption: 1, negotiation_id: vm.selectProductsNegotiation[x].id}).then(() => {
                                // vm.addUpdateStatusInsertMessage("decline", vm.selectProductsNegotiation[x]);
                                vm.$router.go();
                            });
                        });
                    }
                }
            } else {
                    vm.loading = false;
                    let error = "Please select a product to be accepted or declined.";
                    vm.errors.push(error);
                }
                setTimeout(() => {
                    vm.successMessage = '';
                    vm.errors = [];
                }, 5000);
        },

        async confirmProductNegotiation(){
            const vm = this;
                if(vm.confirmationPassword != null && vm.confirmationPassword.length >= 6){
                   let isAuth = await auth.signInWithEmailAndPassword(vm.user.about.email, vm.confirmationPassword).then(async() => {
                        return true;
                    }).catch(() => {
                        vm.confirmationPopupError = "This password is invalid."
                        vm.confirmNegotiationLoading = false;
                        setTimeout(() => {
                            vm.confirmationPopupError = null;
                        }, 4000);
                        return;
                    });
                    if(isAuth){
                        if(vm.clickUpdateNegotiationList){
                            vm.loading = true;
                            vm.confirmNegotiationLoading = true;
                            Array.from(Array(vm.selectProductsNegotiation.length).keys()).map(async (x) => {
                                await vm.updateProductNegotiationStatus(vm.selectProductsNegotiation[x])
                            });
                            vm.loading = false;
                        } else {
                            vm.confirmNegotiationLoading = true;
                            if(vm.acceptProductNegotiation != null){
                                await vm.updateProductNegotiationStatus(vm.acceptProductNegotiation);
                                vm.acceptProductNegotiation = null
                            }
                            vm.confirmNegotiationLoading = false;
                            vm.confirmationNegotiationPopup = false;                      
                            return;
                        }   
                    }

                }
        },

        async updateProductNegotiationStatus(productNegotiation){
            const vm = this;
            let ref = await db.collection('products_negotiation').doc(productNegotiation.id);
            ref.update({is_product_negotiation_status: 1, is_hide_paymentOption: 1, negotiation_id: productNegotiation.id}).then(async() => {
                // await vm.addUpdateStatusInsertMessage("accept", productNegotiation);
                await vm.acceptNegotiationRequest(productNegotiation);
            }).catch((error) => {
                console.log(error)
            });             
        },

        async acceptNegotiationRequest(productNegotiation){
                const vm = this;
                let ref = await db.collection('orders').doc();
                let orderID = ref.id;
                let data = {};
                data.id = orderID;
                data.buyerID = productNegotiation.buyerID;
                data.commodityID = productNegotiation.commodityID;
                data.producerID = productNegotiation.producerID;
                data.total = productNegotiation.total;
                data.paymentOption = productNegotiation.paymentOption
                data.amount = productNegotiation.amount
                data.currency = productNegotiation.currency
                data.offer_price = productNegotiation.offer_price
                data.created =  firebase.firestore.FieldValue.serverTimestamp();
                data.update = firebase.firestore.FieldValue.serverTimestamp();
                await db.collection('orders').doc(orderID).set(data).then(async() => {
                    let ref =  await db.collection('products_negotiation').doc(productNegotiation.id);
                    ref.delete().then(async() => {
                        await vm.storeAndUpdateTotalAmount(productNegotiation);
                        setTimeout(() => {
                            vm.$router.go();
                        }, 5000);
                    })
                })
        },


        async storeAndUpdateTotalAmount(productNegotiation){
            const vm = this;
            await db.collection("accepted_amounts")
                                .doc().set({
                                    buyerID: vm.user.uid,
                                    amount: productNegotiation.amount,
                                    commodityID: productNegotiation.commodityID,
                                    commodityNegotiationID: productNegotiation.id
                                });
            let acceptAmountRef = await db.collection("accepted_amounts")
                    .where("commodityID", "==", productNegotiation.commodityID)
                    .get()

            let acceptedAmount = acceptAmountRef.docs
                    .filter((o) => !o.data().cancelled)
                    .map((o) => {
                        return { amount: o.data().amount };
                    });

            let ref = await db.collection("commodities")
                    .doc(productNegotiation.commodityID)
                    .get();        
            
            let commodity = null;
            if (ref.exists) { commodity = { ...ref.data() }; }

            let total = parseFloat(commodity.total);
            acceptedAmount.forEach((o) => (total = total - parseFloat(o.amount)));
            db.collection("commodities").doc(productNegotiation.commodityID).update({accepted_amount: total})
            return;
        },

        async removeProductFromNegotiation(item){
            const vm = this;
            if(confirm('Are you sure would like to remove product?')){
                vm.loading = true;
                vm.moveToGrainCartButton = true;
                vm.editButton = true;
                vm.removeButton = true;
                let ref = db.collection('products_negotiation').doc(item.id);
                ref.delete().then(() => {
                    setTimeout(() => {
                        vm.myNegotiationProducts = [];
                        vm.loading = false;
                        vm.moveToGrainCartButton = false;
                        vm.removeButton = false;
                        vm.editButton = false;
                        vm.getMyNegotiationProducts();                    
                    }, 5000);
                });
                this.loading = false;
            }
        },

        async deleteProductNegotiation(productId){
            let ref = db.collection('products_negotiation').doc(productId);
            ref.delete().then(() => {
                return true;
            });
        },


        getUniqueValueFromArray(products){      
            return [
                ...new Map(
                    products.map((item) => [item['id'], item])
                ).values()
            ]
        },

        primaryImage(images){
            let arr = images.filter(i=>i.name);
            if(arr.length==0) return {thumbURL:'/img/NGF_NO_IMAGE_45x45.png',largeURL:'/img/NGF_NO_IMAGE.png'}
            arr = arr.filter(i=>i.primary);
            if(arr.length==0) return images[0];
            else return arr[0];
        },

        myDashboard(){
            this.$emit('back-to')
        },

        formatDate(d){
            if(d){
                d = d.toDate();
                if(moment().diff(d,'minutes')<1) return moment().diff(d,'seconds') + ' secs ago';
                if(moment().diff(d,'hours')<1) return moment().diff(d,'minutes') + ' mins ago';
                if(moment().diff(d,'days')<1) return moment().diff(d,'hours') + ' hrs ago';
                return moment(d).format('ll');
            }else{
                return '';
            }
        },

        openProductNegotiation(id){
            this.$router.push('/product/negotiation/'+id);
        },

        editPaymentOption(product){
            this.$router.replace("/commodity/view/" + product.commodityID + "/" + product.id);
        },

        // async addUpdateStatusInsertMessage(status, product){
        //     const vm = this;
        //     let ref = db.collection('inbox').doc();
        //     let inboxID = ref.id;
        //     let data = {
        //         producerID: vm.user.uid,
        //         commodityID: product.commodityID,
        //         product_negotiation_id: product.id,
        //         entity: 'product_negotiation',
        //         from: vm.user.uid,
        //         to: product.buyerID,
        //         created: firebase.firestore.FieldValue.serverTimestamp(),
        //         title: "STATUS",
        //         unread: true,
        //     }
        //     data.type = (status === "decline") ? "alert":"update",
        //     data.text = `Your payment negotiation request was ${status}`
        //     await db.collection('inbox').doc(inboxID).set(data);
        // },

        resetConfirmationModel(){
            let vm = this;
            vm.confirmationPopupError = null;
            vm.confirmationPassword = null;
            vm.selectNegotiationList = false;
        },

    }
}

</script>
<style>
.header-flex{
    flex-direction: row; 
}

@media screen and (max-width:767px) {
    .header-flex{
        flex-direction: column; 
    }
}


</style>