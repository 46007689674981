<template>
  <div>
      <div v-if="errors.length > 0">
          <b-alert show variant="danger">
              <small>{{ errors[0] }}</small>
          </b-alert>
      </div>
      <b-list-group>
          <b-list-group-item v-for="(item,index) in items" :key="item.id">
            <div class="input-group m-0">
              <input type="text" class="form-control" v-on:change="saveItem(item,index)" v-model="item.name">
              <div class="input-group-append">
                <b-button variant="danger" @click="removeItem(index)"><span class="fa fa-times"></span></b-button>
              </div>
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="input-group">
              <input type="text" v-model="newItem" class="form-control">
              <div class="input-group-append">
                <b-button @click="addItem()" variant="success"><span class="fa fa-plus"></span></b-button>
              </div>
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <b-button @click="save" :disabled="!hasChanges&&!saving" block variant="primary"><strong><i v-if="saving" class="fa fa-spinner mr-2"></i><i v-else class="fa fa-save mr-2"></i>SAVE</strong></b-button>
          </b-list-group-item>
      </b-list-group>
  </div>
</template>
<script>
import Vue from "vue";
import {db} from '@/firebase';
export default {
  name: 'ListBuilder',
  props: [
    'category',
    'type',
    'table'
  ],
  components: {
  },
  data(){
      return {
        items:[],
        removed:[],
        newItem:'',
        hasChanges:null,
        saving:null,
        errors:[],
      }
  },
  computed:{
  },
  watch:{
    category(){
      this.loadItems();
    },
    type(){
      this.loadItems();
    }
  },
  methods: {
    async save(){
      this.saving=true;
      var batch = db.batch();
      this.items.forEach((item)=>{
          let ref = db.collection(this.table).doc(item.id);
          batch.set(ref,item);
      });
      this.removed.forEach(item=>{
        let ref = db.collection(this.table).doc(item.id);
        batch.delete(ref);
      });
      await batch.commit()
      this.hasChanges=false;
      this.saving=false;
    },
    addItem(){
      let vm = this;
      if(this.newItem == ''){
          vm.errors.push('Please enter Validate Name')
          setTimeout(() => {
              vm.errors = [];
          }, 3000);
          this.$emit('backToTop');
          return;
      }

      if(vm.checkDuplicateNameValidation() != 0){
          vm.errors.push('This name already exists in list.')
          setTimeout(() => {
              vm.errors = [];
          }, 3000);
          this.$emit('backToTop');
          return;
      }
      let ref = db.collection(this.table).doc();
      let data = {id:ref.id, name:this.newItem};
      if(this.category) data.category=this.category;
      if(this.type) data.type=this.type;
      this.items.push(data);
      vm.newItem='';
      vm.hasChanges=true;
      vm.$emit('saveList',vm.list);
    },

    checkDuplicateNameValidation(){
        return this.items.filter((item) => {
            return (item.name.toLowerCase() === this.newItem.toLowerCase());
        });
    },

    saveItem(item,index){
      let vm = this;
      Vue.set(vm.items,index,item);
      vm.hasChanges=true;
      vm.$emit('saveList',vm.list);
    },
    removeItem(index){
      let vm = this;
      vm.removed.push(vm.items[index]);
      vm.items = vm.items.filter((item,i)=>i!=index);
      vm.hasChanges=true;
      vm.$emit('saveList',vm.list);
    },
    async loadItems(){
      this.items=[];
      let items = null;
      if(this.category){
          items = await db.collection(this.table).where('category','==',this.category).orderBy('name','asc').get();
      }else if(this.type){
          items = await db.collection(this.table).where('type','==',this.type).orderBy('name','asc').get();
      }else{
          items = await db.collection(this.table).orderBy('name','asc').get();
      }
      items.forEach(item=>this.items.push({...item.data(),id:item.id}));
    }
  },
  async mounted() {
      this.loadItems();
  }
}
</script>
<style>.input-group > .input-group-append > .btn {margin: 0;border-top-right-radius: 30px;border-bottom-right-radius: 30px;}</style>
