import { firebase } from "@firebase/app"
import "@firebase/auth"
import "@firebase/firestore"
import "@firebase/storage"
import "@firebase/functions"
// import "@firebase/messaging"

const localhost = "localhost"

export const firebaseConfig = {
     apiKey:process.env.VUE_APP_FIREBASE_CONFIG_API_KEY,
     authDomain:process.env.VUE_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
     databaseURL:process.env.VUE_APP_FIREBASE_CONFIG_DATABASE_URL,
     projectId:process.env.VUE_APP_FIREBASE_CONFIG_PROJECT_ID,
     storageBucket:process.env.VUE_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
     messagingSenderId:process.env.VUE_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
     appId:process.env.VUE_APP_FIREBASE_CONFIG_APP_ID
};

const app = firebase.initializeApp(firebaseConfig);

const auth = app.auth()
const db = app.firestore()
const firestorage = app.storage();
const functions = app.functions();
// const messaging = app.messaging();

// eslint-disable-next-line no-restricted-globals
if (location.hostname === localhost) {
     
     auth.useEmulator('http://127.0.0.1:9099/')
     functions.useEmulator(localhost, 5001)
     db.settings({
          mockUserToken:{
          user_id: "HoFKBKrzqSDu5jEh1WVu0tqclrFB"  // this string can be anything it just needs to be set to connect
          },
          experimentalForceLongPolling: true
     })
     db.useEmulator(localhost, 8080)
     firestorage.useEmulator(localhost, 9199, {
          mockUserToken:{
          user_id: "HoFKBKrzqSDu5jEh1WVu0tqclrFB"  // this string can be anything it just needs to be set to connect
     }})
    
}


export { db, auth, firestorage, app }
// export { db, auth, firestorage, app, messaging }