<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="tablist">
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-0" role="tab">
                            <b-button block variant="primary" class="m-0 d-flex justify-content-between align-items-center" v-b-toggle.myNegotiation>
                                <span class="h5 mb-0">My Product Negotiations</span>
                                <div>
                                    <span class="when-opened">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </b-button>
                        </b-card-header>
                        <b-collapse
                            accordion="my-accordion"
                            role="tabpanel" 
                            id="myNegotiation"
                        >
                            <b-card-body>
                                <div>
                                    <MyNegotiation />
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="tablist">
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-0" role="tab">
                            <b-button block variant="primary"  class="m-0 d-flex justify-content-between align-items-center" v-b-toggle.myProductionContract>
                                <span class="h5 mb-0">My Production Contract Negotiations </span>
                                <div>
                                    <span class="when-opened">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </b-button>
                        </b-card-header>
                        <b-collapse
                            accordion="my-accordion"
                            role="tabpanel"
                            id="myProductionContract"
                        >
                            <b-card-body>
                                <MyProductsContractNegotiation />
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="tablist">
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-0" role="tab">
                            <b-button block variant="primary"  class="m-0 d-flex justify-content-between align-items-center" v-b-toggle.myProductBidRequest>
                                <span class="h5 mb-0">My Product Bid Negotiations </span>
                                <div>
                                    <span class="when-opened">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </b-button>
                        </b-card-header>
                        <b-collapse
                            accordion="my-accordion"
                            role="tabpanel"
                            id="myProductBidRequest"
                        >
                            <MyProductBidRequestsNegotiation />
                        </b-collapse>
                    </b-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


import MyNegotiation from "@/views/Dashboard/MyProductsNegotiation.vue"
import MyProductsContractNegotiation from "@/views/Dashboard/ProductionContract/MyProductionContractListingNegotiation.vue"
import MyProductBidRequestsNegotiation from "@/views/Dashboard/BidRequest/MyProductBidRequestsListingNegotiation.vue"
import {mapState} from 'vuex';



export default {
    computed: {
        ...mapState(['user']),
    },
    components: {
        MyNegotiation,
        MyProductsContractNegotiation,
        MyProductBidRequestsNegotiation
    },
}
</script>

<style>
.container {
    max-width: 1440px;
}

.card-body {
    padding: 0;
}

.card {
    border: 0;
    padding: 0;
}

.collapsed > div .when-opened,
    .not-collapsed > div .when-closed {
        display: none;
    }

</style>