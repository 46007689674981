<template>
    <div>
        <div v-if="isMessageTimelineShow">
            <time-line>
                <time-line-item
                    :badge-type="variant(newMessage.type)"
                    :badge-icon="'fa ' + icon(newMessage.type)"
                    inverted
                >
                    <div slot="header" class="d-flex mt-2 mt-md-0 justify-content-between ">
                    <h5 v-if="!newMessage.type" class="m-0">Update Status</h5>
                        <b-button v-if="!newMessage.type&&user.role.admin&&!completeProductionContract.cancelled" variant="outline-danger" class="pull-right mt-0" size="sm" @click="showCancelConfirmation=true">Cancel Production Contract</b-button>
                        <b-badge v-else :variant="variant(newMessage.type)" class="text-capitalize">{{newMessage.type}}</b-badge>
                        <b-modal hide-footer v-model="showCancelConfirmation" id="modalCancelProductionContract" title="Cancel Production Contract" size="md" variant="danger" :header-bg-variant="'danger'" :header-text-variant="'white'">
                            <p>
                                <strong>Are you sure you want to cancel this production contract?</strong><br />
                                <small>Cancelling the production contract will allow other farmer to full fill the Acres attributed to this production contract.</small>
                            </p>
                            <p></p>
                            <b-form-group label="Reason for cancellation">
                                <b-form-textarea rows="5" placeholder="Reason" v-model="cancellationReason" />
                            </b-form-group>
                            <div class="w-100 text-right">
                                <b-button variant="secondary" size="sm" @click="showCancelConfirmation=false" :disabled="saving">
                                    Cancel
                                </b-button>
                                <b-button variant="danger" size="sm" class="ml-2" @click="cancelProductionContract" :disabled="saving">
                                    <i v-if="saving" class="fa fa-spinner mr-2"></i>Continue
                                </b-button>
                            </div>
                        </b-modal>
                    </div>
                    <div slot="content">
                        <template v-if="!newMessage.type">
                            <p class="text-muted mt-0">Send a message, provide a shipping update, provide a general update or alert of something important.</p>
                            <div class="row mt-4">
                                <div class="col-6 col-md-3 text-center">
                                    <b-button @click="clickOnNewMessageType('message')" variant="link">
                                        <div class="timeline-badge-choose bg-info"><i class="fa fa-comments"></i></div>
                                        <div class="mt-2"><strong class="text-dark">Message</strong></div>
                                    </b-button>
                                </div>
                                <div class="col-6 col-md-3 text-center">
                                <b-button @click="clickOnNewMessageType('shipping')" variant="link">
                                    <div class="timeline-badge-choose bg-warning"><i class="fa fa-truck"></i></div>
                                    <div class="mt-2"><strong class="text-dark">Shipping</strong></div>
                                </b-button>
                                </div>
                                <div class="col-6 col-md-3 text-center">
                                <b-button @click="clickOnNewMessageType('documents')" variant="link">
                                    <div class="timeline-badge-choose bg-success"><i class="fa fa-check"></i></div>
                                    <div class="mt-2"><strong class="text-dark">Documents</strong></div>
                                </b-button>
                                </div>
                                <div class="col-6 col-md-3 text-center">
                                <b-button @click="clickOnNewMessageType('alert')" variant="link">
                                    <div class="timeline-badge-choose bg-danger"><i class="fa fa-exclamation-triangle"></i></div>
                                    <div class="mt-2"><strong class="text-dark">Alert</strong></div>
                                </b-button>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div v-if="newMessage.type !== 'documents'">
                                <div v-if="uploading" class="row mt-4 mb-4">
                                    <div class="col-12 text-center">
                                        <b-progress :value="progressUpload" variant="primary" striped :animated="true"></b-progress>
                                        <br />
                                        <small>Uploading...</small>
                                    </div>
                                </div>
                                <div v-if="fileErrors.length > 0" class="row mt-4 mb-4">
                                    <div tabindex="-1" class="d-block invalid-feedback" v-for="(error, index) in fileErrors" :key="index">
                                        {{ error }}
                                    </div>
                                </div>  
                                <ValidationObserver ref="form">
                                    <form @submit.prevent="validate">
                                        <div class="row">
                                            <div class="col-12 pt-2">
                                                <ValidationProvider name="newMessage.to" rules="required" v-slot="{ passed, failed }">
                                                    <b-form-group v-if="user.role.admin" label="Recipient" :invalid-feedback="'Who is this update intended for?'"
                                                        :state="(failed?false:(passed?true:null))">
                                                        <b-form-checkbox-group id="checkbox-recipient" v-model="newMessage.to" name="recipient">
                                                            <b-form-checkbox :value="completeProductionContract.buyerID">{{'User ID: ' + completeProductionContract.buyerID.substr(0, 10) + `(${completeProductionContract.buyer.about.first + " " + completeProductionContract.buyer.about.last})`}}</b-form-checkbox>
                                                            <b-form-checkbox :value="completeProductionContract.producerID">{{'User ID: ' + completeProductionContract.producerID.substr(0, 10) +  `(${completeProductionContract.producer.about.first + " " + completeProductionContract.producer.about.last})`}}</b-form-checkbox>
                                                        </b-form-checkbox-group>
                                                    </b-form-group>
                                                    <b-form-group v-else-if="completeProductionContract.productionContract.userID !== user.uid" label="Recipient" :invalid-feedback="'Who is this update intended for?'"
                                                        :state="(failed?false:(passed?true:null))">
                                                        <b-form-checkbox-group id="checkbox-recipient" v-model="newMessage.to" name="recipient">
                                                            <b-form-checkbox :value="completeProductionContract.buyerID">{{'User ID: ' + completeProductionContract.buyerID.substr(0, 10)}}</b-form-checkbox>
                                                            <b-form-checkbox :value="'admin'" disabled>NGF Global</b-form-checkbox>
                                                        </b-form-checkbox-group>
                                                    </b-form-group>
                                                    <b-form-group v-else-if="completeProductionContract.productionContract.userID === user.uid" label="Recipient" :invalid-feedback="'Who is this update intended for?'"
                                                        :state="(failed?false:(passed?true:null))">
                                                        <b-form-checkbox-group id="checkbox-recipient" v-model="newMessage.to" name="recipient">
                                                            <b-form-checkbox :value="completeProductionContract.producerID">{{'User ID: ' + completeProductionContract.producerID.substr(0, 10)}}</b-form-checkbox>
                                                            <b-form-checkbox :value="'admin'" disabled>NGF Global</b-form-checkbox>
                                                        </b-form-checkbox-group>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 pt-2">
                                                <ValidationProvider
                                                    name="newMessage.title"
                                                    rules="required"
                                                    v-slot="{ passed, failed }"
                                                >
                                                    <b-form-group
                                                    label="Title"
                                                    :invalid-feedback="'Title is required'"
                                                    :state="(failed?false:(passed?true:null))"
                                                    >
                                                    <b-form-input placeholder="Title" v-model="newMessage.title"  :state="(failed?false:(passed?true:null))"/>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 pt-2">
                                                <ValidationProvider
                                                    name="newMessage.text"
                                                    rules="required"
                                                    v-slot="{ passed, failed }"
                                                >
                                                    <b-form-group
                                                    label="Message"
                                                    :invalid-feedback="'Message is required'"
                                                    :state="(failed?false:(passed?true:null))"
                                                    >
                                                    <b-form-textarea rows="5" placeholder="Message" v-model="newMessage.text"  :state="(failed?false:(passed?true:null))"/>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div v-if="newMessage.type=='message'||newMessage.type=='shipping'">
                                            <div class="col-12 pt-2">
                                                <div class="col-md-5 text-start pt-2">
                                                    <a @click="selectFile" class="text-muted mt-2 add-pointer" title="Upload File" >
                                                        <i class="fa fa-upload mr-1"></i>Upload File
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="newMessageUploadedFile.length > 0" class="row mt-4 mb-4 list-group">
                                            <div v-for="(file, index) in newMessageUploadedFile" :key="index">
                                                <div class="col-12 text-start" style="word-break: break-all;">
                                                    <a :disabled="deleting" @click="deleteFile(file.data, index)" class="text-danger mr-2 add-pointer">
                                                        <span class="fa fa-times"></span>
                                                    </a>
                                                    <strong><em class="text-muted">{{ file.data.fileNameShow }}</em></strong>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </ValidationObserver>
                            </div>
                            <div v-else>
                            </div>
                        </template>
                    </div>
                    <div v-if="newMessage.type" slot="footer">
                        <div v-if="newMessage.error" class="row">
                            <div class="col-12">
                                <b-alert variant="danger">One or more required fields are missing.</b-alert>
                            </div>
                        </div>
                        <div class="row" v-if="newMessage.type !=='documents'">
                            <div class="col-6 text-center"><b-button block size="sm"  variant="secondary" @click="cancelMessage()" :disabled="saving">Cancel</b-button></div>
                            <div class="col-6 text-center"><b-button block size="sm" :variant="variant(newMessage.type)" @click="submit" :disabled="saving"><i v-if="saving" class="fa fa-spinner mr-2"></i>Submit</b-button></div>
                        </div>
                    </div>
                </time-line-item>
                <input class="d-none" id="upload" type="file"  name="upload" ref="uploadInput" accept="video/*, image/*,application/pdf, .webm,.mkv,.avi ,.flv,.mov,.doc, .docx,.txt" :multiple="true" @change="detectFiles($event)"/>
                <time-line-item
                    :badge-type="variant(m.type)"
                    :badge-icon="'fa '+icon(m.type)"
                    :inverted="(index % 2 == 1)"
                    v-for="(m, index) in timeline"
                    :key="m.id"
                >
                    <div slot="header" class="d-flex">
                        <span :class="'badge badge-' + (variant(m.type))">{{(m.type=='message'?m.from.toUpperCase():m.type.toUpperCase())}}</span>
                        <small class="w-100 text-muted text-right"><i class="ti-time"></i> {{formatDate(m.created)}}</small>
                    </div>
                    <div slot="content">
                        <div v-if="m.title"><strong>{{m.title}}</strong></div>
                        <div v-if="m.text" class="text-muted">{{m.text}}</div>
                        <div v-if="user.role.admin">
                            <div v-for="(file, index) in m.messageAttachment" :key="index">
                                <div class="col-12 text-start" style="word-break: break-all;">
                                    <a @click="viewFile(file)" class="text-info mr-2" title="view">
                                        <span class="fa fa-external-link text-black add-pointer"></span>
                                    </a>
                                    <strong>
                                    <em class="text-muted" v-if="file.fileNameShow">{{ file.fileNameShow }}</em>
                                    <em class="text-muted" v-else>{{ file.name }}</em>
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div v-for="(file, index) in m.messageAttachment" :key="index">
                                <div class="col-12 text-start" style="word-break: break-all;">
                                    <a @click="viewFile(file)" class="text-info mr-2" title="view">
                                        <span class="fa fa-external-link text-black add-pointer"></span>
                                    </a>
                                    <strong>
                                    <em class="text-muted" v-if="file.fileNameShow">{{ file.fileNameShow }}</em>
                                    <em class="text-muted" v-else>{{ file.name }}</em>
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </time-line-item>
            </time-line>
        </div>
        <div v-else class="mt-4">
            <b-card no-body class="overflow-hidden" style="max-width: 630px; float: right; border-radius: 0.1875rem">
                <b-row no-gutters>
                    <b-card-body title="Documents">
                        <div v-if="!isDocumentsLoading">
                            <div v-if="getAllAttachDocumentsFiles.length >= 1">
                                <div v-for="file in getAllAttachDocumentsFiles" :key="file.id">
                                    <div class="col-12 text-start" style="word-break: break-all;">
                                        <a @click="viewFile(file)" class="text-info mr-2" title="view">
                                            <span class="fa fa-external-link text-black add-pointer"></span>
                                        </a>
                                        <strong>
                                            <em class="text-muted" v-if="file.fileNameShow">{{ file.fileNameShow }}</em>
                                            <em class="text-muted" v-else>{{ file.name }}</em>
                                        </strong>
                                    </div>
                                </div>
                                <div class="col-12 text-center"><b-button block size="sm"  variant="secondary" @click="cancelMessage()" :disabled="saving">Back</b-button></div>
                            </div>
                            <div v-else>
                                <div>
                                    No Documents found
                                </div>
                                <div class="col-12 text-center"><b-button block size="sm"  variant="secondary" @click="cancelMessage()" :disabled="saving">Back</b-button></div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="mt-4">
                                Loading.......
                            </div>
                        </div>
                    </b-card-body>
                </b-row>
            </b-card>
        </div>
    </div>
</template>

<script>

import {mapState} from 'vuex'
import {db} from '@/firebase';
import { collectionData, docData } from 'rxfire/firestore';
import { map, switchMap } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';
import { TimeLine, TimeLineItem } from "@/components";
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import firebase from 'firebase';
import Vue from "vue";
import moment from 'moment';
import { firestorage } from '@/firebase';



Vue.prototype.moment = moment;

export default{
    props: ['completeProductionContract'],
    computed:{
        ...mapState(['user'])
    },
    data(){
        return {
            saving:false,
            timeline:[],
            newMessage:{
                type:null,
                title:null,
                text:null,
                to:[]
            },
            getMessageAttachmentFiles: [],
            uploading: false,
            uploadMessageFile: '',
            newMessageUploadedFile: [],
            fileErrors: [],
            getAttachmentsError: [],
            getAllAttachDocumentsFiles: [],
            tempData: [],
            showCancelConfirmation:false,
            deleting: false,
            progressUpload: 0,
            cancellationReason:'',
            isMessageTimelineShow: true,
            isDocumentsLoading: false
        }
    },
    async created() {
        this.getMessages();
    },
     components: {
        TimeLine,
        TimeLineItem,
        ValidationObserver,
        ValidationProvider
    },
    methods:{
        async clickOnNewMessageType(messageType){
            const vm = this;
            vm.newMessage.type = messageType
            if(messageType === 'documents'){
                vm.isDocumentsLoading = true;
                vm.isMessageTimelineShow = false;
                await vm.getAllDocumentsFromMessage();
            }
        },

        async getAllDocumentsFromMessage(){
            let vm = this;
            vm.getAllAttachDocumentsFiles = [];
            Array.from(Array(vm.timeline.length).keys()).map(x => {
                if(vm.timeline[x].messageAttachment.length >= 1){
                    vm.getAllAttachDocumentsFiles.push(...vm.timeline[x].messageAttachment)
                }
            })
            setTimeout(() => {
                vm.isDocumentsLoading = false;
            }, 3000);
        },

        getMessages(){
            let vm = this;
            vm.newMessage={
                type:null,
                title:null,
                text:null,
                error:null,
                to:[]
            }
            if(!vm.user.role.admin) vm.newMessage.to=['admin'];
            vm.loading=true;
            let ref = db.collection('messages');
            ref = ref.where('completeProductionContractID','==', this.completeProductionContract.id);
            ref = ref.where('to','==',(vm.user.role.admin?'admin':vm.user.uid)).orderBy('created','desc');
            collectionData(ref).pipe(
                switchMap(inbox=>{
                    if(inbox.length==0) return of([]);
                    return combineLatest(...inbox.map(m=>{
                        return docData(db.collection('users').doc(m.from)).pipe(
                        map(u=>{
                            let from = '';
                            if (u.business != undefined) {
                                from = (u.business.type == 'admin' ? 'NGF Global' : (vm.user.role.admin ? `User ID: ${m.from.substr(0, 10)}` : (vm.user.uid == m.from ? 'Message' : `User ID: ${m.from.substr(0,10)}`)))
                                // from = (u.business.type == 'admin' ? 'NGF Global' : (vm.user.role.admin ? u.about.first + ' ' + u.about.last : (vm.user.uid == m.from ? 'me' : (u.business.type == "producer") ? 'Farmer' : u.business.type)))
                            }
                            return { ...m, from: from }
                        })
                        )
                    }))
                })
            ).subscribe(async(inbox)=>{
                vm.timeline=[];
                    inbox.map(async(m) =>{
                        let getAttachment = await this.getAllMessageAttachments(m.id);
                        vm.timeline.push({...m, messageAttachment: getAttachment})
                    }
                );
                vm.loading=false;
            });
        },

        getAllMessageAttachments(messageId){
            let getAttachment = [];
            if(messageId != '' && messageId != undefined){
                let getAttachmentRef = db.collection('message_attachments');
                getAttachmentRef = getAttachmentRef.where('messageId','==',messageId);
                getAttachmentRef.get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        getAttachment.push(doc.data())
                    });
                });
                return getAttachment;
            } else {
                return getAttachment;
            }
        },

        formatDate(d){
            if(d){
                d = d.toDate();
                if(moment().diff(d,'minutes')<1) return moment().diff(d,'seconds') + ' secs ago';
                if(moment().diff(d,'hours')<1) return moment().diff(d,'minutes') + ' mins ago';
                if(moment().diff(d,'days')<1) return moment().diff(d,'hours') + ' hrs ago';
                return moment(d).format('ll');
            }else{
                return '';
            }
        },

        async viewFile(file){
            this.getAttachmentsError = [];
            if(file != '' && file != undefined){
                let attachmentRef = await firestorage.ref(file.fullPath);
                attachmentRef.getDownloadURL().then((url) => {
                    window.open(url,'_blank');
                }).catch(() => {
                        let errorMessage = 'Please refresh the Page';
                        this.getAttachmentsError.push({errorMessage, messageId: file.messageId});
                        setTimeout(() => {
                        this.getAttachmentsError = [];
                        }, 5000); 
                        return false; 
                });
            }
        },


        variant(type){
            return (type=='shipping'?'warning':(type=='update'?'success':(type=='alert'?'danger':'info')));
        },
        icon(type){
            return (type=='shipping'?'fa-truck':(type=='update'?'fa-check':(type=='alert'?'fa-exclamation-triangle':'fa-comments')));
        },

        async cancelProductionContract(){
            let vm = this;
            vm.saving = true;
            vm.completeProductionContract.cancelled = true;
            await this.updateAmountRef();
            await db.collection('production_contracts_complete').doc(vm.completeProductionContract.id).update({cancelled:true}).then(async() => {
                await this.updateAmountRef(vm.completeProductionContract.productionContractNegotiationId);
            });
            let to = [];
            to=[vm.completeProductionContract.producerID,vm.completeProductionContract.buyerID,'admin'];
            to.forEach(async(item) => {
                let data = {
                    type:'alert',
                    title:'Production Contract Cancelled',
                    text:this.cancellationReason,
                    to: item,
                    entity:'complete_production_contract_execute',
                    from:vm.user.uid,
                    unread:true,
                    completeProductionContractID: vm.completeProductionContract.id,
                    productionContractID: vm.completeProductionContract.productionContractID,
                    updated:firebase.firestore.FieldValue.serverTimestamp(),
                    created:firebase.firestore.FieldValue.serverTimestamp()
                }
                await vm.newUpdateMessage(data);
                await vm.sendMessageInbox(data);
            });
            vm.saving = false;
            vm.showCancelConfirmation = false;
        },

        async updateAmountRef(){
            await db.collection('production_contract_accepted_amounts')
                .where('productionContractNegotiationID', '==', this.completeProductionContract.productionContractNegotiationId)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach(async(doc) => {
                        await db.collection('production_contract_accepted_amounts').doc(doc.id).delete();
                    });
                });
            
           let acceptAmountRef = await  db.collection('production_contract_accepted_amounts')
                                          .where('productionContractID', '==', this.completeProductionContract.productionContractID)
                                          .get();
           let acceptedAmount = acceptAmountRef.docs.filter((o) => !o.data().cancelled)
                                               .map((o) => {
                                                    return { amount: o.data().fullFillAmount };
                                                });
            let ref = await db.collection('production_contract')
                              .doc(this.completeProductionContract.productionContractID)
                              .get();

            let productionContract = null;
            if(ref.exists) productionContract = {...ref.data()};
            let total = parseInt(productionContract.available_acres);
            acceptedAmount.forEach((o) => (total = total  - parseInt(o.amount)));
            console.log(total);
            await db.collection('production_contract')
                    .doc(this.completeProductionContract.productionContractID)
                    .update({accepted_full_fill_amount: total});
                
        },

        async submit(){
            let vm = this;
            vm.saving = true;

            let res = await vm.$refs.form.validate();

            if(res){

                let promises = [];
                let to = [];

                if(vm.newMessage.to.length>0){
                    vm.newMessage.to.forEach(uid=>{
                        to.push(uid);
                    });
                    if(!to.includes('admin')) to.push('admin');
                    if(!vm.user.role.admin&&!to.includes(vm.user.uid)) to.push(vm.user.uid);
                } else {
                    to=[vm.completeProductionContract.producerID,vm.completeProductionContract.buyerID,'admin'];
                }

                to.forEach(to=>{
                    let ref = db.collection('messages').doc();
                    let data = {
                        ...vm.newMessage,
                        from:vm.user.uid,
                        to:to,
                        unread:true,
                        completeProductionContractID: this.completeProductionContract.id,
                        productionContractID:vm.completeProductionContract.productionContractID,
                        id: ref.id,
                        is_hide: false,
                        updated:firebase.firestore.FieldValue.serverTimestamp(),
                        created:firebase.firestore.FieldValue.serverTimestamp()
                    }
                    vm.newUpdateMessage(data).then(async() => {
                        this.newMessageAttachmentsInsert(data.id);
                        this.sendMessageInbox(data);
                    });
                });

                await Promise.all(promises);

                vm.newMessage = {
                    type:null,
                    title:null,
                    text:null,
                    error:null,
                    to:[]
                };
                vm.newMessageUploadedFile = [];
                if(!vm.user.role.admin) vm.newMessage.to=['admin'];
            } else {
                vm.newMessage.error=true;
            }
            vm.saving = false;
        },

        async sendMessageInbox(data){
            let ref = await db.collection('inbox').doc();
            let inboxID = ref.id;
            let inboxData = {
                id: ref.id,
                productionContractID: data.productionContractID,
                completeProductionContractID: data.completeProductionContractID,
                entity: 'complete_production_contract_execute',
                unread: true,
                to: data.to,
                from: data.from,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                title: data.title,
                type: data.type,
                text: data.text
            }
            await db.collection('inbox').doc(inboxID).set(inboxData);
        },

        async newMessageAttachmentsInsert(messageId){
            if(messageId != '' &&  this.newMessageUploadedFile.length > 0){
                Array.from(Array(this.newMessageUploadedFile.length).keys()).map((x) => {
                    let newMessageUploadedFile = {...this.newMessageUploadedFile[x].data,messageId:messageId};
                    db.collection('message_attachments').add(newMessageUploadedFile).then(() => {});
                });
            }
        },
        selectFile () {
            this.fileErrors = [];
            this.$refs.uploadInput.click();
        },
        async deleteFile(file, fileIndex){ 
            if(confirm("Are you sure you want to delete this file?")){
                this.deleting=true;
                let storageRef = firestorage.ref(file.fullPath);
                await storageRef.delete();
                this.newMessageUploadedFile.splice(fileIndex, 1);
                this.deleting=false;
            }
        },
        async upload(file, currentTimeStamp){
            this.uploading = true;
            this.saving = true;
            let vm = this;
            vm.progressUpload = 0;
            let getFileName = file.name.split('.').slice(0, -1).join('.');
            let getFileExtension = file.name.split('.').pop();
            let fileName = this.user.uid + '_'+ currentTimeStamp  + '_' + getFileName + '.' + getFileExtension;
            let uploadMessageFile = firestorage.ref('message_attachments/' + this.completeProductionContract.id +'/'+  this.user.uid + '/' + fileName).put(file);
            await uploadMessageFile.on('state_changed', (sp) => {
                vm.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
            }, 
            null,
            async () => { 
                vm.progressUpload = 100;
                let data = await uploadMessageFile.snapshot.ref.getMetadata();
                for(let key in data){
                if(data[key] === undefined) delete data[key];
                }
                vm.uploading = false;
                vm.saving = false;
                data['fileNameShow'] = file.name;
                vm.newMessageUploadedFile.push({...vm.newMessageUploadedFile, data});
            });
        },

        async detectFiles(e){
            let fileList = e.target.files;
            let fileAccept = ['application/pdf', 'video/quicktime','application/msword', 'video/x-flv','video/mp4', 'image/jpeg', 'image/png', 'image/gif', 'video/x-matroska','video/x-msvideo','video/webm','application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']; 
            let  timestamp = Math.floor(Date.now() / 1000);
            if(fileList.length > 0){
                for(const file of fileList){
                    if(fileAccept.includes(file.type)){
                        timestamp += 100;
                        await this.upload(file, timestamp);
                    } else {
                        let errorMessage = 'Please select valid document file. file will be accept this extension (.PDF, .TXT and .DOC/.DOCX.)';
                        this.fileErrors.push(errorMessage);
                        return false;
                    }
                }
            }
        },

        async newUpdateMessage(message){
            let ref = db.collection('messages').doc(message.id);
            ref.set(message);
            return ref;
        },

        cancelMessage(){
            this.newMessage.type = null;
            this.isMessageTimelineShow = true;

            this.fileErrors = [];
            this.getAttachmentsError = [];
            this.newMessage = {
                type:null,
                title:null,
                text:null,
                error:null,
                to: []
            };
            if(!this.user.role.admin){
                this.newMessage.to = ['admin']
            } 
            if(this.newMessageUploadedFile.length > 0){
                Array.from(Array(this.newMessageUploadedFile.length).keys()).map((x) => {
                    let attachmentRef = firestorage.ref(this.newMessageUploadedFile[x].data.fullPath);
                    attachmentRef.delete().then(() => {
                    });
                });
                this.newMessageUploadedFile = [];
            }
        }
    },

    beforeDestroy(){
        this.getMessages();
    }




}

</script>
<style>
.timeline-badge-choose {
  display:inline-block;
  color: #FFFFFF;
  width: 50px;
  height: 50px;
  line-height: 51px;
  font-size: 1.4em;
  text-align: center;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
.add-pointer {
  cursor: pointer;
}
.card-timeline .timeline > li:last-child:after{
 display: none;
}
.card-timeline .timeline:before {
    top: 40px;
}
</style>


