<template>
    <div class="container">
        <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                <h4 class="card-title mb-4">
                    <b-spinner class="mr-2"></b-spinner> Loading...
                </h4>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div class="row" v-else>
            <div class="d-block d-md-none col-12">
                <b-button @click="backTo" variant="primary">
                    <i class="fa fa-chevron-left mr-2"></i> Back
                </b-button>
            </div>    
            <div class="col-12">
                <div class="row">
                    <div class="col-12 pt-2">
                        <div class="border-2 bg-white rounded p-4">
                            <div class="text-success">
                                <h1 class="h3">
                                    <span>{{ showBidRequestSubtypeType }}</span>  
                                </h1>
                                <h5 class="h6">
                                    {{ bidRequest.variety }}
                                </h5>
                            </div>
                            <div class="d-flex flex-column flex-md-row justify-content-between border border-1 border-dark text-md-left mt-3">
                                <span class="m-0 text-uppercase equal-width font-weight-bold d-block px-2 pt-2 pb-0 pb-md-2">Crop Year  </span>
                                <span class="border-1 border-dark equal-width border-left p-2"> {{ (bidRequest.year != undefined) ? bidRequest.year : "-"  }}</span>  
                            </div>
                            <div class="d-flex flex-column flex-md-row justify-content-between  border-right border-left border-bottom border-1 border-dark text-md-left">
                                <span class="m-0 text-uppercase equal-width font-weight-bold px-2 pt-2 pb-0 pb-md-2">{{ bidRequest.priceOption }} Price (Per {{bidRequest.unit}})   </span>
                                <span class="border-1 border-dark equal-width border-left p-2" v-if="bidRequest.price != null">  {{(bidRequest.price >= 0) ? "$" : "-$" }}{{ (bidRequest.price >= 0) ?  numeral(Math.abs(bidRequest.price)).format('0,000.00') : '' }}</span>
                                <span class="border-1 border-dark equal-width border-left p-2" v-else>-</span>
                            </div>
                            <div class="d-flex flex-column flex-md-row justify-content-between  border-right border-left border-bottom border-1 border-dark text-md-left">
                                <span class="m-0 text-uppercase equal-width  font-weight-bold px-2 pt-2 pb-0 pb-md-2">Expires by  </span>
                                <span class=" border-1 border-dark equal-width  border-left p-2"> {{ moment(bidRequest.expires.toDate()).format("ll") }}</span>  
                            </div>
                            <div class="d-flex flex-column flex-md-row justify-content-between  border-right border-left border-bottom border-1 border-dark text-md-left">
                                <span class="m-0 text-uppercase equal-width  font-weight-bold px-2 pt-2 pb-0 pb-md-2">Delivery Window  </span>
                                <span class=" border-1 border-dark equal-width  border-left p-2"> {{moment(bidRequest.startDate.toDate()).format('L')}} : {{moment(bidRequest.endDate.toDate()).format('L')}}</span>  
                            </div>
                            <div class="d-flex flex-column flex-md-row justify-content-between  border-right border-left border-bottom border-1 border-dark text-md-left">
                                <span class="m-0 text-uppercase equal-width  font-weight-bold px-2 pt-2 pb-0 pb-md-2">Total Number of {{bidRequest.unit}}</span>
                                <span class=" border-1 border-dark equal-width  border-left p-2"> {{ numeral(availableAcresTotal).format('0,000') }}</span>  
                            </div>
                            <div class="d-flex flex-column flex-md-row justify-content-between  border-right border-left border-bottom border-1 border-dark text-md-left">
                                <span class="m-0 text-uppercase equal-width  font-weight-bold px-2 pt-2 pb-0 pb-md-2">Details</span>
                                <span class=" border-1 border-dark equal-width  border-left p-2" v-html="bidRequest.details"></span>  
                            </div>
                            <div class="d-flex flex-column flex-md-row justify-content-between border-right border-left border-bottom border-1 border-dark text-md-left" v-if="specifications.length > 0">
                                <span class="m-0 text-uppercase equal-width font-weight-bold px-2 pt-2 pb-0 pb-md-2">Certifications</span>
                                <span class="border-1 border-dark equal-width  border-left p-2">
                                    <div v-if="specifications.length > 0">
                                        <div v-for="specification in specifications" :key="specification.id">
                                            <span v-if="specification.url != undefined">
                                                <a class="text-dark" @click="$router.push('/external-url/' +specification.url)" target="_blank" v-if="specification.file == undefined">
                                                    {{ specification.specification }}<i class="fa fa-check-circle text-info ml-2"></i>
                                                </a>
                                                <a class="text-dark" :href="specification.url" target="_blank" v-else>
                                                    {{ specification.specification }}<i class="fa fa-check-circle text-info ml-2"></i>
                                                </a>
                                            </span>
                                            <span v-else>
                                                 {{ specification.specification }}<i class="fa fa-check-circle text-info ml-2"></i>  
                                            </span>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <ValidationObserver ref="form" class="col" >
                                <form @submit.prevent="validate" v-if="user.uid !== bidRequest.userID && !user.role.admin">
                                    <div class="d-block d-md-flex text-center text-md-left" v-if="user.uid !== bidRequest.userID && !user.role.admin">
                                        <div class="row">
                                            <div class="col-12 pt-2">
                                                <ValidationProvider
                                                    name="bid_Request_negotiation.fullFillAmount"
                                                    :rules="{required:true,decimal:true,minimum:{minimum:10}, available: {total: totalAvailable}}"
                                                    v-slot="{ passed, failed, errors }"
                                                >
                                                    <b-form-group  :state="(failed?false:(passed?true:null))">
                                                        <div>
                                                            <legend class="col-form-label">How many {{ bidRequest.unit }} can you commit?</legend>
                                                            <b-input-group>
                                                                <b-form-input placeholder="Enter amount" v-model="bid_Request_negotiation.fullFillAmount" :state="(failed?false:(passed?true:null))"></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-input-group-text>{{ bidRequest.unit }}</b-input-group-text>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                        </div>
                                                        <b-form-invalid-feedback :state="(failed?false:(passed?true:null))">
                                                            {{ errors[0] == "Exceeds" ? 'The commit '+ bidRequest.unit +' entered exceed ' + bidRequest.unit + ' the available.' : 'Please enter a valid number.' }}
                                                        </b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12" v-if="bidRequest.priceOption === 'Make Offer'">
                                                <ValidationProvider
                                                    name="offerPrice"
                                                    :rules="{required: true}"
                                                    v-slot="{passed, failed, errors}"   
                                                >
                                                <b-form-group :state="(failed?false:(passed?true:null))">
                                                    <div>
                                                        <legend class="col-form-label">Offer Price</legend>
                                                            <b-form-input placeholder="Enter offer price" ref="currencyInput" class="currencyInput mb-0" v-currency="priceFormatOptions"  v-model="offerPrice" :state="(failed?false:(passed?true:null))"></b-form-input>
                                                    </div>
                                                    <b-form-invalid-feedback :state="(failed?false:(passed?true:null))">
                                                        {{ errors[0] == "Exceeds" ? '' : 'Please enter a valid number.' }}
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-block d-md-flex" v-if="user.uid !== bidRequest.userID && !user.role.admin">
                                        <div class="flex-fill text-center text-md-left">
                                            <b-button variant="success" @click="backTo"><i class="fa fa-arrow-left mr-2"></i>Back</b-button>
                                        </div>
                                        <div class="flex-fill text-center text-md-right">
                                            <div>
                                                <b-button @click="validate('accept')" variant="success">Submit Offer</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ValidationObserver>
                            <div class="d-block d-md-flex" v-if="(user.uid == bidRequest.userID)">
                                <div class="flex-fill text-center text-md-left">
                                    <b-button variant="success" @click="backTo"><i class="fa fa-arrow-left mr-2"></i>Back</b-button>
                                </div>
                                <div class="flex-fill text-center text-md-right">
                                    <div>
                                        <b-button variant="success" @click="editBidRequest(bidRequest.id);">Edit</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>      
        </div>
        <div v-if="!loading">
            <div>
                <div class="row" v-if="!loading">
                    <div class="col-12 pt-2"><hr /></div>
                </div>
                <div class="row">
                    <div class="col-12 pt-2"><h5>Questions </h5></div>
                </div>

                <div v-if="(bidRequest.userID !== user.uid && !user.role.admin) || (bidRequest.questions.length == 0 && user.role.admin)">
                    <BidRequestQuestions  id="question" 
                        :currentUserID="user.uid"
                        :isPriorMessageAvailableOrNot= "(user.role.admin && bidRequest.questions.length == 0)"
                        :bidRequestID="bidRequest.id" 
                        @reloadQuestions="reloadQuestions"
                        :showSenderCheckbox = "false"
                    />
                </div>
                <div v-else-if="bidRequest.questions.length > 0" role="tablist">
                    <BidRequestQuestions  id="question" 
                        v-if="showQuestionModule && user.role.admin"
                        :currentUserID="user.uid"
                        :bidRequestID="bidRequest.id" 
                        :isPriorMessageAvailableOrNot= "true"
                        @reloadQuestions="reloadQuestions" 
                        :showSenderCheckbox = "false"
                        />
                    <b-card
                            no-body class="mb-1"
                            v-for="question in getUniqueValue(bidRequest.questions)"
                            :key="'cardQuestions' + question.id"
                    >
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block @click="clickQuestionMessage(question.id)" variant="info">
                                {{ question.isFirstMessageAdmin ? "NGF Global " : "User ID: " }}  {{ (question.isFirstMessageAdmin) ? '' : question.producerID.substr(0, 10).toUpperCase()}} 
                                <b-badge v-if="question.unread" variant="warning" class="ml-2">
                                    <strong>UNREAD</strong>
                                </b-badge>
                            </b-button>
                        </b-card-header>
                        <b-collapse
                            :id="'collapse' + question.id"
                            :visible="selectedProducerQuestions == question.id"
                            accordion="my-accordion"
                            role="tabpanel"
                        >
                        <b-card-body>
                                <BidRequestQuestions
                                    v-if="selectedProducerQuestions == question.id"
                                    :id="'questions' + question.id"
                                    :currentUserID="question.producerID"
                                    :bidRequestID="bidRequestID"
                                    :isPriorMessageAvailableOrNot= "false"
                                    :showSenderCheckbox = "user.role.admin && !question.isFirstMessageAdmin"
                                />
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>
        </div>
        <div>
            <form @submit.prevent="confirmBidRequestNegotiation">
                <b-modal ref="model" size="lg" 
                    title="Confirmation of Negotiation" centered  v-model="confirmationPopup" 
                    @show="resetConfirmationModel" 
                    @hide="resetConfirmationModel"
                >
                    <div v-if="confirmNegotiationLoading" class="row mt-4">
                        <div class="col d-none d-md-flex"></div>
                        <div class="col-12 col-md-8 col-xl-6 pt-4">
                            <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                            <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                            </card>
                        </div>
                        <div class="col d-none d-md-flex"></div>
                    </div>
                    <div v-else>
                        <p>
                            By entering your password, you acknowledge and consent to enter into a legally binding contract with the Buyer, for the execution of the Bid Request pursuant to the NGF Global <a href="https://www.ngf-global.com/user-agreement" target="_blank">User Agreement</a>
                        </p>
                        <p v-if="confirmationPopupError" class="alert alert-danger mb-4">{{confirmationPopupError}}</p>
                        <ValidationProvider
                            name="confirmationPassword"
                            rules="required|min:6"
                            v-slot="{ passed, failed, errors }"
                            >
                            <b-form-group
                                :invalid-feedback="errors.length > 0 ? errors[0] : null"
                                :state="failed ? false : passed ? true : null"
                            >
                                <b-form-input
                                type="password"
                                placeholder="Please Enter Password"
                                v-model="confirmationPassword"
                                :class="confirmationPopupError ? 'is-invalid' : ''"
                                :state="failed ? false : passed ? true : null"
                                />
                            </b-form-group>
                            </ValidationProvider>
                    </div>
                    <template #modal-footer="{ok,cancel}">
                    <b-button size="xl" :disabled="confirmNegotiationLoading" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button size="xl" :disabled="confirmNegotiationLoading" variant="success" @click="confirmBidRequestNegotiation">
                        Continue
                    </b-button>
                    </template>
                </b-modal>
            </form>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { db } from "@/firebase";
import { bus } from "@/vuex/bus.js";
import { firestorage } from "@/firebase";
import { required } from "vee-validate/dist/rules";
import { ValidationProvider, extend } from "vee-validate";
import {auth} from '@/firebase';
import firebase from 'firebase';
import BidRequestQuestions from "./components/Questions.vue";
import { getValue } from "vue-currency-input";


extend("required", required);
extend("decimal",n=>!isNaN(parseFloat(n)) && isFinite(n));

extend("available", {
  params: ["total"],
  validate: (amount, {total})=>{
    return (parseFloat(amount)<=total);
  },
  message:'Exceeds'
});

extend("minimum", {
  params: ["minimum"],
  validate: (amount, {minimum})=>{
    return (parseFloat(amount)>=minimum);
  },
  message:'Minimum'
});


export default {
    components:{
        ValidationProvider,
        BidRequestQuestions
    },

    computed: {
    ...mapState(["user"]),
        availableAcresTotal() {
            return this.accepted_amounts;
        },
        showBidRequestSubtypeType: function(){
            const vm = this;
            return vm.bidRequest.subtype+" - "+  vm.bidRequest.type;
        }
    },  
    

    data(){
        return {
            bidRequestID: null,
            bidRequest: null,
            specifications: [],
            bid_Request_negotiation: {
                fullFillAmount: null,
                offerPrice: null,
            },
            totalAvailable: null,
            offerPrice: null,
            confirmationPopup: false,
            confirmationPopupError :  null,
            confirmationPassword: null,
            confirmNegotiationLoading: false,
            selectedProducerQuestions: null,
            accepted_amounts: null,
            priceFormatOptions: {
                locale:"en",
                currency: "USD",
                precision: 2,
                distractionFree: false,
                valueAsInteger: false,
                autoDecimalMode: true,
                allowNegative: true
            },
            error:false,
            priceError: false,
            loading: true,
            showQuestionModule: true,
        }
    },
    async mounted(){
        const vm = this;
        vm.load();
    },

    methods: {
        async load(){
            const vm = this;
            let data = await vm.checkBidRequestIdExistOrNot(vm.$route.params.id);
            if(vm.$route.params.id){
                vm.bidRequestID = data;
                let ref = await db.collection("bid_request").doc(vm.bidRequestID).get();
                if(ref.exists){
                    vm.bidRequest = { ...ref.data()};
                    let acceptAmountRef = await db
                    .collection("bid_request_accepted_amounts")
                    .where("bidRequestID", "==", vm.bidRequestID)
                    .get();

                    let acceptedAmount = acceptAmountRef.docs
                    .filter((o) => !o.data().cancelled)
                    .map((o) => {
                        return { amount: o.data().fullFillAmount };
                    });

                    let total = parseFloat(vm.bidRequest.total);
                    acceptedAmount.forEach((o) => (total = total - parseFloat(o.amount)));
                    vm.accepted_amounts = parseInt(total);

                    vm.totalAvailable = total;

                    await vm.loadingOptionAndTypes();
                    await vm.loadingMessage();
                    await vm.loadSpecifications();

                    bus.$emit('prepareTitle',{...vm.bidRequest, isBidRequest:true});

                    vm.loading = false;
                } else {
                    bus.$emit('prepareTitle', null);
                    vm.loading = false;
                }
                
            } else {
                vm.loading = false;
            }
        },
        async loadingOptionAndTypes(){
                const vm = this;
                let ref = await db.collection("units").doc(vm.bidRequest.unit).get();
                if (ref.exists) vm.bidRequest = {...vm.bidRequest, unit: ref.data().name} 

                ref = await db.collection('bid_price_options').doc(vm.bidRequest.priceOption).get();
                if (ref.exists) vm.bidRequest = {...vm.bidRequest, priceOption: ref.data().name}

                ref = await db.collection("commodity_types").doc(vm.bidRequest.type).get();
                if (ref.exists) vm.bidRequest = { ...vm.bidRequest, type: ref.data().name };

                ref = await db.collection("commodity_subtypes").doc(vm.bidRequest.subtype).get();
                if (ref.exists) vm.bidRequest = { ...vm.bidRequest, subtype: ref.data().name };

                ref = await db.collection('commodity_categories').doc(vm.bidRequest.category).get();
                if (ref.exists) vm.bidRequest = {...vm.bidRequest, category: ref.data().name};
        },

        async loadingMessage(){
                const vm = this;
                vm.bidRequest.questions = [];
                let ref = db.collection("inbox");
                ref = ref.where("entity", "==", "bidRequest");
                ref = ref.where("bidRequestID", "==", vm.bidRequestID);
                ref = ref.where("to",'==', vm.user.role.admin?vm.bidRequest.userID : vm.user.uid)

                let docs = await ref.get();
                    if (!docs.empty){
                    vm.bidRequest.questions = docs.docs.map((d) => {
                        return { ...d.data(), id: d.id };
                    });
                } else {
                    vm.bidRequest.questions = [];
                }

                var items = [];
                var lastProducerId = "";

                vm.bidRequest.questions.forEach((item) => {
                    let checkIfProducer = vm.checkIfProducerExist(items, item.producerID);
                    if(lastProducerId !== item.producerID && checkIfProducer.length == 0 && (item.isBidRequestCreateByBuyer != true || item.isBidRequestCreateByBuyer == undefined) ){
                        items.push(item);
                    }
                    lastProducerId = item.producerID;
                    if(item.producerID == vm.user.uid && vm.user.role.admin){
                        vm.showQuestionModule = false;
                    }
                });
                vm.bidRequest.questions = items;
        },

        reloadQuestions: function reloadQuestions(){ 
            const vm = this;
            vm.loading = true;
            vm.showQuestionModule = false;
            vm.specifications = [];
            vm.bidRequest = []
            vm.load();
        },


        async validate(status){
            const vm = this;
            let res = await vm.$refs.form.validate();
            if(vm.bidRequest.priceOption == "Make Offer"){
                let offerPrice = getValue(vm.$refs.currencyInput);
                if(offerPrice == null){
                    return;
                }
                vm.bid_Request_negotiation.offerPrice = offerPrice
            }

            if(res){
                if(status == "accept"){
                    vm.confirmationPopup = true;
                } 
            } else {
                vm.error=true;
                vm.saving=false;
            }
        },

        async confirmBidRequestNegotiation(){
            const vm = this;
            vm.confirmNegotiationLoading = true;
            let isAuth = await auth.signInWithEmailAndPassword(vm.user.about.email, vm.confirmationPassword).then(async() => {
                            return true;
                        }).catch(() => {
                            vm.confirmationPopupError = "This password is invalid."
                            vm.confirmNegotiationLoading = false;
                            setTimeout(() => { vm.confirmationPopupError = null; }, 4000);
                            return;
                        });

            if(isAuth){
                let bid_request = {};
                let ref = db.collection('bid_request_negotiation').doc();
                let id =  ref.id;
                bid_request.id = id;
                bid_request.bidRequestID = vm.bidRequest.id;
                bid_request.producerID = vm.user.uid;
                bid_request.buyerID= vm.bidRequest.userID;
                bid_request.updated = firebase.firestore.FieldValue.serverTimestamp();
                bid_request.created = firebase.firestore.FieldValue.serverTimestamp();
                bid_request.fullFillAmount = parseInt(vm.bid_Request_negotiation.fullFillAmount);
                bid_request.is_producer_verified = true;
                if(vm.bidRequest.priceOption == "Make Offer"){
                    bid_request.offerPrice = vm.bid_Request_negotiation.offerPrice;
                 }
                let data = {...bid_request};
                await db.collection('bid_request_negotiation').doc(id).set(data).then(() => {
                    setTimeout(() => { vm.$router.replace('/'); }, 3000);
                });
            }
        },
        
        async loadSpecifications(){
            const vm = this;
            let promises = [];
            if (vm.bidRequest.specifications) {
                vm.bidRequest.specifications.map((c) =>
                    promises.push(vm.getSpecification(c))
                );
            }

            let specifications = await Promise.all(promises);
            specifications.filter((c) => c).map((c) => vm.specifications.push(c));
        },

        async checkBidRequestIdExistOrNot(parametersID){
            const vm = this;
            let getBidRequestRef = await db.collection("bid_request").where('slug', '==', parametersID).get();
            if(!getBidRequestRef.empty){
                  const data = getBidRequestRef.docs.map((bidRequest) => {
                      return bidRequest.data().id;
                  });
                  return data[0];
            } else {
                let getBidRequestRef = await db.collection("bid_request").where('id', '==', parametersID).get();
                if(!getBidRequestRef.empty){
                    const data = getBidRequestRef.docs.map((bidRequest) => {
                        return bidRequest.data().id;
                    });
                    return data[0];
                } else {
                    vm.loading = false
                    return null;
                }
            }

          
        },

        checkIfProducerExist(items, producerID){
            return items.filter((i) => {
                return i.producerID.includes(producerID);
            });
        },

        async getSpecification(c) {
            try {
                let url = ""
                if(c.file){
                   url = await firestorage.ref(c.file.fullPath).getDownloadURL();
                } else {
                   url = c.url;
                }
                let ref = await db.collection("specifications").doc(c.specification).get();
                return { ...c, url: url, specification: ref.data().name };
            } catch (e) {
                return null;
            }
        },

        backTo() {
            bus.$emit("backToTop");
        },

        editBidRequest(bidRequestID){
            const vm = this;
            vm.$router.replace("/bid/request/edit/" + bidRequestID)
        },

        resetConfirmationModel(){
            const vm = this;
            vm.confirmationPopupError = null;
            vm.confirmationPassword = null;
        },

        getUniqueValue(questions){
            return [
                ...new Map(
                questions.map((item) => [item['id'], item])
                ).values()
            ];
        },

        clickQuestionMessage(questionId) {
            const vm = this;
            if (vm.selectedProducerQuestions == questionId) {
                return (vm.selectedProducerQuestions = null);
            }
            return (vm.selectedProducerQuestions = questionId);
        },

    }
}
</script>

