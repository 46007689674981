<template>
    <div class="container">
        <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div class="row" v-else>
            <div class="col-12" v-if="bidRequestNegotiation != null">
                <div>
                    <div class="border-2 bg-white rounded p-4">
                        <div class="d-block d-md-flex">
                            <div class="flex-fill text-center text-md-left text-muted">User ID: {{ bidRequestNegotiation.producerID.substr(0, 10) }}</div>
                        </div>
                        <div class="d-block d-md-flex">
                            <div class="flex-fill text-center text-md-left pt-3"><h6 class="d-inline"> {{ fullName }} </h6></div>
                        </div>
                        <div class="d-block d-md-flex">
                            <div class="flex-fill text-center text-md-left pt-3">
                                <h6 class="d-inline"> 
                                    Total {{ bidRequestNegotiation.bidRequest.unit }}: {{ numeral(bidRequestNegotiation.bidRequest.accepted_full_fill_amount ?  bidRequestNegotiation.bidRequest.accepted_full_fill_amount : bidRequestNegotiation.bidRequest.total ).format('0,000') }}
                                </h6>
                            </div>
                        </div>
                        <div class="d-block d-md-flex">
                            <div class="flex-fill text-center text-md-left pt-3">
                                <h6 class="d-inline">
                                    Delivery Window: {{moment(startDate).format('ll')}} to {{moment(endDate).format('ll')}}
                                </h6>
                            </div>
                        </div>
                        <div class="d-block d-md-flex">
                            <div class="flex-fill text-center text-md-left pt-3">
                                <h6 class="d-inline">
                                    {{ bidRequestNegotiation.bidRequest.priceOption }} Price (Per {{bidRequestNegotiation.bidRequest.unit}}): {{(bidRequestNegotiation.bidRequest.price >= 0) ? "$" : "-$" }}{{ (bidRequestNegotiation.bidRequest.price >= 0) ?  numeral(Math.abs(bidRequestNegotiation.bidRequest.price)).format('0,000.00') : '' }}
                                </h6>
                            </div>
                        </div>
                        <div class="d-block d-md-flex">
                            <div class="flex-fill text-center text-md-left pt-3">
                                <h6 class="d-inline">
                                    Farmer Fulfilled Number of {{ bidRequestNegotiation.bidRequest.unit }}: {{ numeral(fullFillAmount).format('0,000') }}
                                </h6>
                            </div>
                            <div class="flex-fill text-center text-md-right">
                                <strong class="text-uppercase">Total Projected Value:</strong>
                                <h3 class="d-inline font-weight-bold">
                                    ${{ numeral(totalProjectValue).format('0,000.00')}}
                                </h3>
                            </div>
                        </div>
                        <div class="d-block d-md-flex" v-if="bidRequestNegotiation.offerPrice">
                            <div class="flex-fill text-center text-md-left pt-3">
                                <h6 class="d-inline">
                                    Farmer Offer Price: ${{ numeral(bidRequestNegotiation.offerPrice).format('0,000.00')  }}
                                </h6>
                            </div>
                        </div>
                        <div class="d-block d-md-flex" v-if="bidRequestNegotiation.bidRequest.userID === user.uid">
                            <div class="flex-fill text-center text-md-right">
                                <div v-if="bidRequestNegotiation.is_bidRequest_negotiation_status == undefined">
                                    <b-button @click="validateStatus('accept')" variant="success">Accept</b-button>
                                    <b-button @click="validateStatus('decline')" variant="danger" class="ml-2">Decline</b-button>
                                </div>
                                <div v-else>
                                    <div class="flex-fill text-center text-md-left pt-2">
                                        <b-badge variant="success" pill v-if="bidRequestNegotiation.is_bidRequest_negotiation_status == 1">Approved</b-badge>
                                        <b-badge variant="danger" pill v-if="bidRequestNegotiation.is_bidRequest_negotiation_status == 0">Rejected</b-badge>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-block d-md-flex" v-if="bidRequestNegotiation.bidRequest.userID !== user.uid">
                            <b-button variant="success" @click="backTo"><i class="fa fa-arrow-left mr-2"></i>Back</b-button>
                        </div>
                    </div>
                    <div class="border-2 bg-white rounded p-4 mt-4" v-if="bidRequestNegotiation.bidRequest.userID !== user.uid">
                        <h5>Product Bid Negotiation Request</h5>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <p v-if="bidRequestNegotiation.is_bidRequest_negotiation_status !== undefined">
                                    <b-badge variant="success" pill v-if="bidRequestNegotiation.is_bidRequest_negotiation_status == 1">Approved</b-badge>
                                    <b-badge variant="danger" pill v-if="bidRequestNegotiation.is_bidRequest_negotiation_status == 0">Rejected</b-badge>
                                </p>
                                <p v-else>
                                    <b-badge variant="warning" pill>Pending Approval</b-badge>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 ml-6" v-else>
                <div class="col d-none d-md-flex"></div>
                <div class="col-12 col-md-8 col-xl-6 pt-4">
                    <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                        <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                        <template v-if="user.role.producer">
                            <h4 class="card-title mt-0 mb-4">Your Product Bid negotiation request does not exists please contact NGF team. </h4>
                            <p><a href="/bid/request" class="text-info">Browse Bid Request</a></p>
                            <p><a target="_blank" href="https://www.ngf-global.com/contact-us" class="text-info">Contact our team with any questions you may have</a></p>
                        </template>
                        <template v-else>
                            <h4 class="card-title mt-0 mb-4">You do not have any negotiations Product Bid at this time.</h4>
                            <p><a target="_blank" href="https://www.ngf-global.com/contact-us" class="text-info">Contact our team with any questions you may have</a></p>
                        </template>
                    </card>
                </div>
                <div class="col d-none d-md-flex"></div>
            </div>
        </div>
        <div>
            <form ref="acceptForm" @submit.prevent="confirmNegotiation">
                <b-modal ref="model" size="lg" 
                    title="Confirmation of Negotiation" centered  v-model="confirmationPopup" 
                    @show="resetConfirmationModel" 
                    @hide="resetConfirmationModel"
                >
                    <div v-if="confirmNegotiationLoading" class="row mt-4">
                        <div class="col d-none d-md-flex"></div>
                        <div class="col-12 col-md-8 col-xl-6 pt-4">
                            <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                            <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                            </card>
                        </div>
                        <div class="col d-none d-md-flex"></div>
                    </div>
                    <div v-else>
                        <p>
                            By entering your password, you acknowledge and consent to enter into a legally binding contract with the Farmer, pursuant to the NGF Global <a href="https://www.ngf-global.com/user-agreement" target="_blank">User Agreement</a>
                        </p>
                        <p v-if="confirmationPopupError" class="alert alert-danger mb-4">{{confirmationPopupError}}</p>
                        <ValidationProvider
                            name="confirmationPassword"
                            rules="required|min:6"
                            v-slot="{ passed, failed, errors }"
                            >
                            <b-form-group
                                :invalid-feedback="errors.length > 0 ? errors[0] : null"
                                :state="failed ? false : passed ? true : null"
                            >
                                <b-form-input
                                type="password"
                                placeholder="Please Enter Password"
                                v-model="confirmationPassword"
                                :class="confirmationPopupError ? 'is-invalid' : ''"
                                :state="failed ? false : passed ? true : null"
                                />
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <template #modal-footer="{ok,cancel}">
                        <b-button size="xl" :disabled="confirmNegotiationLoading" @click="cancel()">
                            Cancel
                        </b-button>
                        <b-button size="xl" :disabled="confirmNegotiationLoading" variant="success" @click="confirmNegotiation">
                            Continue
                        </b-button>
                    </template>
            
                </b-modal>
            </form>
        </div>
    </div>

</template>

<script>

import { mapState } from 'vuex';
import {db} from '@/firebase';
import { bus } from "@/vuex/bus.js";
import {auth} from '@/firebase';
import firebase from 'firebase';



export default {
    computed:{
        ...mapState(['user']),
        fullName: function(){
            const vm = this;
            return vm.bidRequestNegotiation.bidRequest.subtype+ " " + vm.bidRequestNegotiation.bidRequest.type;
        },
        startDate: function(){
            return this.bidRequestNegotiation.bidRequest.startDate.toDate(); 
        }, 
        endDate: function(){
            return this.bidRequestNegotiation.bidRequest.endDate.toDate();
        },
        fullFillAmount: function(){
            return this.bidRequestNegotiation.fullFillAmount;
        },
        totalProjectValue: function(){
            const vm = this;
            return ((vm.bidRequestNegotiation.offerPrice) ? vm.bidRequestNegotiation.offerPrice : vm.bidRequestNegotiation.bidRequest.price) * vm.bidRequestNegotiation.fullFillAmount;
        }
    },

    data() {
        return {
            loading: true,
            bidRequestNegotiationId: '',
            bidRequestNegotiation: null,
            confirmationPopup: false,
            confirmationPopupError: null,
            confirmationPassword: null,
            confirmNegotiationLoading: false,
            updateStatus: null,
        }
    },

    async mounted(){
        const vm = this;
        await vm.getBidRequestNegotiation();
        bus.$emit('prepareTitle', null);
    },

    methods:{
        async getBidRequestNegotiation(){
            const vm = this;
            if(vm.$route.params.id) vm.bidRequestNegotiationId = vm.$route.params.id;

            let bidRequestNegotiation = await db.collection('bid_request_negotiation').doc(vm.bidRequestNegotiationId).get();
            if(bidRequestNegotiation.exists) vm.bidRequestNegotiation = {...bidRequestNegotiation.data(),id:bidRequestNegotiation.id};

            if(vm.bidRequestNegotiation != null){
                let bidRequest = await db.collection('bid_request').doc(vm.bidRequestNegotiation.bidRequestID).get();
                if(bidRequest.exists) vm.bidRequestNegotiation.bidRequest = {...bidRequest.data(),id: bidRequest.id};

                let ref = await db.collection('units').doc(vm.bidRequestNegotiation.bidRequest.unit).get();
                if(ref.exists) vm.bidRequestNegotiation.bidRequest = {...vm.bidRequestNegotiation.bidRequest,unit:ref.data().name};

                ref = await db.collection('bid_price_options').doc(vm.bidRequestNegotiation.bidRequest.priceOption).get();
                if (ref.exists) vm.bidRequestNegotiation.bidRequest = {...vm.bidRequestNegotiation.bidRequest, priceOption: ref.data().name}

                ref = await db.collection('commodity_types').doc(vm.bidRequestNegotiation.bidRequest.type).get();
                if(ref.exists) vm.bidRequestNegotiation.bidRequest = {...vm.bidRequestNegotiation.bidRequest,type:ref.data().name};

                ref = await db.collection('commodity_subtypes').doc(vm.bidRequestNegotiation.bidRequest.subtype).get();
                if(ref.exists) vm.bidRequestNegotiation.bidRequest = {...vm.bidRequestNegotiation.bidRequest,subtype:ref.data().name};

                ref = await db.collection('users').doc(vm.bidRequestNegotiation.producerID).get();
                if(ref.exists) vm.bidRequestNegotiation.producer = {...ref.data()};
            }

            setTimeout(() => {
                vm.loading = false;
            }, 2000);
        },

        async validateStatus(status){
            const vm = this;
            if(status == "accept"){
                vm.confirmationPopup = true;
                vm.updateStatus = status;
            } else {
                if(confirm('Are you sure want payment request ' + status + '?')){
                    let ref = db.collection('bid_request_negotiation').doc(vm.bidRequestNegotiationId);
                    vm.updateStatus = status;
                    ref.update({is_bidRequest_negotiation_status: (status == "accept") ? 1 : 0, negotiation_id: vm.bidRequestNegotiationId}).then(async() => {
                        vm.$router.replace('/');
                    });
                }
            }
        },

        async confirmNegotiation(){
            const vm = this;
            if(vm.confirmationPassword != null && vm.confirmationPassword.length >= 6){
                vm.confirmNegotiationLoading = true;
                await auth.signInWithEmailAndPassword(vm.user.about.email, vm.confirmationPassword).then(async() => {
                    let ref = db.collection('bid_request_negotiation').doc(vm.bidRequestNegotiationId);
                    ref.update({is_bidRequest_negotiation_status: (vm.updateStatus == "accept") ? 1 : 0,negotiation_id: vm.bidRequestNegotiationId, is_buyer_verified: true }).then(async() => {
                        await vm.completeBidRequest();
                    });
                }).catch(() => {
                    vm.confirmationPopupError = "This password is invalid."
                    vm.confirmNegotiationLoading = false;
                    setTimeout(() => {
                        vm.confirmationPopupError = null;
                    }, 2000);
                });
            }
        },

        async completeBidRequest(){
            const vm = this;
            let data = {};
            let ref = db.collection('bid_request_complete').doc();
            let id =  ref.id;
            data.id = id;
            data.bidRequestID = vm.bidRequestNegotiation.bidRequestID;
            data.buyerID = vm.user.uid;
            data.producerID = vm.bidRequestNegotiation.producerID;
            data.fullFillAmount = vm.bidRequestNegotiation.fullFillAmount;
            data.bidRequestNegotiationId = vm.bidRequestNegotiation.id;
            data.status = 'completed';
            data.created = firebase.firestore.FieldValue.serverTimestamp();
            data.updated = firebase.firestore.FieldValue.serverTimestamp();
            if(vm.bidRequestNegotiation.offerPrice) { data.offerPrice = vm.bidRequestNegotiation.offerPrice }
            data.total = (((vm.bidRequestNegotiation.offerPrice) ?  vm.bidRequestNegotiation.offerPrice : vm.bidRequestNegotiation.bidRequest.price) * vm.bidRequestNegotiation.fullFillAmount);
            await db.collection('bid_request_complete').doc(id).set(data).then(async() =>{
                let ref = await db.collection('bid_request_negotiation').doc(vm.bidRequestNegotiation.id);
                ref.delete().then(async() => {
                    await vm.deactivateBidRequest();
                    setTimeout(() => {
                        vm.$router.replace('/');                        
                    }, 2000);
                });   
            });
        },

        async deactivateBidRequest(){
            const vm = this;
            let ref = await db.collection('bid_request')
                              .doc(vm.bidRequestNegotiation.bidRequestID)
                              .get();
            let bidRequest = null;
            if(ref.exists) bidRequest = {...ref.data()};
            if(parseInt(bidRequest.accepted_full_fill_amount) === 0 && bidRequest.accepted_full_fill_amount != undefined) {
                let ref = await db.collection('bid_request').doc(vm.bidRequestNegotiation.bidRequestID)
                ref.update({'active': false});
            }
            
        },

        backTo() {
            bus.$emit("backToTop");
        },

        resetConfirmationModel(){
            const vm = this;
            vm.confirmationPopupError = null;
            vm.confirmationPassword = null;
        },
    }
}


</script>