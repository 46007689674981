<template>
  <div class="container">
    <AccountStatus />
    <div class="row">
      <div class="col-12 pb-2">
        <div class="bg-white rounded pr-2 pr-md-4 pl-2 pl-md-4 pt-2 pb-2">
          <div><small class="display-inline mr-2">Filters:</small></div>
          <div class="d-block d-md-flex marketplace-filters">
            <Multiselect v-model="selectedCategoryType" track-by="id" label="name" placeholder="Category" :options="filters.category" :searchable="true" :allow-empty="true"  />
            <Multiselect v-model="selectedCommodityType" track-by="id" label="name" placeholder="Product" :options="filters.commodity" :searchable="true" :allow-empty="true" class="ml-0 ml-md-2" />
            <Multiselect v-model="selectedCommoditySubType" track-by="id" label="name" placeholder="Type" :options="filters.subtype" :searchable="true" :allow-empty="true"  class="ml-0 ml-md-2" />
            <Multiselect v-model="selectedCertification" track-by="id" label="name" placeholder="Certification" :options="filters.certification" :searchable="true" :allow-empty="true"  class="ml-0 ml-md-2" />
            <Multiselect v-if="user.role.admin" v-model="selectedStatus" track-by="id" label="name" placeholder="Active" :options="[{id:'yes','name':'Yes'},{id:'no','name':'No'}]" :searchable="true" :allow-empty="true" class="ml-0 ml-md-2" />
            <b-button @click="clearFilters" :disabled="!hasFilters" variant="primary" class="mt-2 mt-md-0 ml-0 ml-md-2">CLEAR</b-button>
          </div>
        </div>
      </div>
      <div class="col-12 pb-2">
        <div class="bg-white rounded d-md-flex pl-2 pt-2 pb-1">
            <div class="col-md-7">
              <div class="">
                <div class="filterOption">
                  <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-radio-group
                        id="btn-radios-1"
                        v-model="filterOption"
                        :options="filterOptions"
                        :aria-describedby="ariaDescribedby"
                        @change="changeFilterOption"
                        name="radios-btn-default"
                        buttons
                      ></b-form-radio-group>
                    </b-form-group>
                </div>
              </div>
              <div class="row" v-if="!filterOption">
                <div class="col-md-6 pb-2">
                        <gmap-autocomplete 
                          @place_changed="setStartPlace"
                          placeholder="Please enter the pickup point"
                          :enable-geolocation="true"
                          :value="startPlace"
                          id="toAddress"
                          :required="true"
                          :select-first-on-enter="true"
                        >
                        </gmap-autocomplete>
                </div>
                <div class="col-md-6">
                      <gmap-autocomplete @place_changed="setDestinationPlace"
                        placeholder="Please enter the destination point"
                        id="fromAddress"
                        :value="destinationPlace"
                        :enable-geolocation="true"
                        :required="true"
                        :select-first-on-enter="true"
                      >
                      </gmap-autocomplete>
                </div>
                <div class="invalid-feedback d-block pl-3" v-if="positionFailed">Please select the location pickup point and destination point.</div>
              </div>
              <div class="row" v-else>
                <div class="col-md-6 pb-2">
                      <gmap-autocomplete @place_changed="setPlaceSpecificLocation"
                          placeholder="Enter location"
                          id="locationAddress"
                          :value="specificPlace"
                          :enable-geolocation="true"
                          :required="true"
                          :select-first-on-enter="true"
                      ></gmap-autocomplete>
                      <!-- <b-button variant="info" @click="getCurrentLocation" title="Current Location"><i class="fa fa-location-arrow"></i></b-button>         -->
                </div>
                <div class="invalid-feedback d-block pl-3" v-if="positionFailed">Please select the location</div>
              </div>
                <div class="pt-2 d-flex flex-column">
                  <div class="mt-1 mb-2"> 
                    <ValidationProvider
                      name="searchRadiusMiles"
                      :rules="{required:true, decimal:0,minimum:10, maximum:maximumLimitOfMiles}"
                      v-slot="{ passed, failed, errors }"
                    >
                      <b-form-group
                        :state="(failed?false:(passed?true:null))"  
                        id="input-group-1"
                        label="Range with search miles:"
                        label-for="input-card" 
                        label-class="font-weight-bold text-dark"
                      >
                        <b-form-input v-model.number="searchRadiusMiles" id="searchMilesRange" min="0" max="100" placeholder="Enter miles" :state="(failed?false:(passed?true:null))" style="width: 100px;" />
                      </b-form-group>
                        <b-form-invalid-feedback :state="(failed?false:(passed?true:null))">
                          {{ errors[0]=='Minimum'?'Enter minmum 10 miles': errors[0]=='Maximum'?'Enter maximum ' + maximumLimitOfMiles + ' miles':'Enter valid miles'}}
                        </b-form-invalid-feedback>
                    </ValidationProvider>
                    <div class="invalid-feedback d-block pl-3" v-if="maximumLimitOfMilesFailed">Enter valid miles</div>
                  </div>
                  <!-- <label for="range-1">Range with min and max search miles</label> -->
                </div>
            </div>
            <div class="col-md-5  d-flex flex-column justify-content-between">
              <div class="d-flex justify-content-md-end">
                <b-button variant="info" @click="searchWithLocation" class="mt-2 mt-md-0 ml-0 ml-md-2">Search Location</b-button>
                <b-button variant="warning" @click="clearLocationFilters" class="mt-2 mt-md-0 ml-0 ml-md-2">Clear</b-button>
              </div>
              <div class="mapOptions  d-flex justify-content-md-end">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    id="btn-radios-1"
                    v-model="viewOption"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="radios-btn-default"
                    buttons
                  ></b-form-radio-group>
                </b-form-group>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div v-if="loading||data.length==0" class="row mt-0 mt-md-4">
      <div class="col d-none d-md-flex"></div>
      <div class="col-12 col-md-8 col-xl-6 pt-4">
        <card class="card-lock text-center mt-4 pb-4" no-footer-line>
          <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
          <h4 v-if="loading" class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
          <h4 v-else class="card-title mb-4">No Commodities Found</h4>
          <p>Please change your filters.</p>
        </card>

      </div>
      <div class="col d-none d-md-flex"></div>
    </div>
    <div v-else>
      <div v-if="!viewOption" class="row">
          <div v-for="commodity in getUniqueValueFromArray(data)" :key="commodity.id" class="col-12 col-md-6 col-lg-4">
              <b-card
                :img-src="(primaryImage(commodity.images).largeURL?primaryImage(commodity.images).largeURL:'/img/NGF_NO_IMAGE.png')"
                img-top
                class="card-commodity border fixed-card-body"
                @click="choose(commodity.id)"
              >
              <template #header>
                <h5 class="text-primary mt-0 mb-0 font-weight-bold d-flex"><span class="w-100">{{commodity.subtype}} {{commodity.type}}</span><i @click="bookmark(commodity.id)" :class="'fa ' + (commodity.bookmarked?'fa-bookmark text-success':'fa-bookmark-o text-secondary') + ' ml-2 mt-2'" v-on:click.stop></i></h5>
              </template>
                <small v-if="commodity.location" class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Location</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{commodity.location}}</strong></small>
                <small class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4"> Price (Per {{commodity.unit}})</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">${{numeral(commodity.price).format('0,000.00')}}</strong></small>
                <small class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Amount ({{commodity.unit}})</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{numeral(commodity.accepted_amount ? commodity.accepted_amount : commodity.total).format('0,000')}}</strong></small>
                <small class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Expires By</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{moment(commodity.expires.toDate()).format('ll')}}</strong></small>
                <small class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Variety</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{commodity.variety}}</strong></small>
                <!-- <small class="d-block d-md-flex mt-2" v-if="(commodity.totalCommodityView[0] != undefined && commodity.totalCommodityView[0] > 0)"><strong variant="danger" class="d-block d-md-inline flex-fill text-left text-md-left text-color-red">{{ commodity.totalCommodityView[0] }} Buyers are looking at this product </strong></small> -->
                <div :class="user.role.admin || commodity.userID == user.uid ? 'buttonMargin' : ''">
                  <b-button @click="shareProductURL(commodity)" variant="info" size="sm" block class="mt-2 fixed-share-bottom" :class="[!user.role.admin && commodity.userID != user.uid ? 'mb-0': '']" v-on:click.stop>Share</b-button>
                  <b-button @click="$router.push('/commodity/edit/'+commodity.id)" variant="primary" v-if="user.role.admin||user.uid==commodity.userID" size="sm" block class="mt-4 fixed-bottom" v-on:click.stop>EDIT</b-button>
                  <b-button :disabled="deleteButton" @click="deleteCommodity(commodity)" variant="danger" v-if="user.role.admin" size="sm" class="mt-4 fixed-delete-bottom" v-on:click.stop>Delete</b-button>
                </div>
              </b-card>
        </div>
      </div>
      <div class="col-12 commodityShowMap p-0 mt-2" v-show="viewOption">
        <div class="p-2 bg-white" style="background: #fff;">
          <div v-if="data.length > 0">
            <gmap-map :zoom ="zoom" :options="{gestureHandling: 'greedy'}" :center="(commodityPlace.length > 0) ? commodityPlace[0].position : data[0].placePosition" style="width:100%; height: 520px;" v-if="data[0].placePosition != undefined" ref="commodityMap" class="mt-0">
                <gmap-cluster  :minimumClusterSize="4" :maxZoom = "5" :gridSize="90" :zoomOnClick="true"  :enableRetinaIcons="true">
                  <gmap-marker
                    :position="commodity.commodityLocation.position"
                    v-for="commodity in getUniqueValueFromArray(data)"
                    :key="commodity.id"
                    @click="openCommodity(commodity.id)"
                    :clickable="true"
                    @mouseover = "statusText = commodity.id"
                    @mouseout="statusText = null"
                  >
                    <gmap-circle
                      :center = "filterOption ? commodityPlace[0].position : routesCenterPoints"
                      :radius=" filterOption ? radiusMeter : routesPointRadius + radiusMeter"
                      ref="circle"
                      :options="{strokeColor: '#000000', strokeOpacity: 0.5, strokeWeight: 1, fillColor:'#FF6600', fillOpacity:0.1}"
                      v-if="commodityPlace.length > 0"
                    >
                    </gmap-circle>
                    <gmap-polyline
                      :path.sync="routesPolylinePath"
                      :editable = "false"
                      ref="polyline"
                      v-if="!filterOption"
                      :options="{strokeColor: '#1967D2', strokeOpacity: 0.7}"
                    >
                    </gmap-polyline>
                  <gmap-info-window
                    :closeclick="true"
                    @closeclick="openCommodity(null)"
                    :opened="openCommodityID === commodity.id"
                    >
                      <div>
                        <b-img thumbnail fluid class="viewProductImage" v-bind:src="(primaryImage(commodity.images).thumbURL?primaryImage(commodity.images).thumbURL:'/img/NGF_NO_IMAGE.png')" /> 
                        <h5 class="text-primary mt-0 mb-0 font-weight-bold d-flex"><span class="w-100">{{commodity.subtype}} {{commodity.type}}</span></h5>
                        <small v-if="commodity.location" class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Location</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{commodity.location}}</strong></small>
                        <small class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4"> Price (Per {{commodity.unit}})</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">${{numeral(commodity.price).format('0,000.00')}}</strong></small>
                        <small class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Amount ({{commodity.unit}})</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{numeral(commodity.accepted_amount ? commodity.accepted_amount : commodity.total).format('0,000')}}</strong></small>
                        <small class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Expires By</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{moment(commodity.expires.toDate()).format('ll')}}</strong></small>
                        <small class="d-block d-md-flex mt-2"><span class="d-block d-md-inline text-left text-muted flex-fill pr-4">Variety</span><strong class="d-block d-md-inline flex-fill text-left text-md-right">{{commodity.variety}}</strong></small>
                        <b-button variant="info" @click="viewCommodity(commodity.id)">View</b-button>
                      </div>
                    </gmap-info-window>
                  </gmap-marker>
                </gmap-cluster>
                <div slot="visible">
                  <div style="bottom: 0; left: 0; background-color: transparent; color: transparent; position: absolute; z-index: 100">
                    {{statusText}}
                  </div>
                </div>
              </gmap-map>
          </div>
          <div v-else>
             No Results 
          </div>
        </div> 
       </div>
    </div>
    <div>
      <b-modal ref="model" centered v-model="copyLinkPopup">
        <template>
          <span><p class="h5 font-bold mb-0">Shareable link has been copied.</p></span>
        </template>
        <template #modal-footer="{ok,cancel}">
          <b-button size="xl" variant="success"  @click="cancel()">
            Close
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import store from '@/vuex/store.js';
import firebase from 'firebase';
import {bus} from '@/vuex/bus.js';
import {mapState} from 'vuex';
import Vue from "vue";
import numeral from 'numeral';
import moment from 'moment';
import {db} from '@/firebase';
import { docData } from 'rxfire/firestore';
import { of, from, combineLatest } from 'rxjs';
import { map, switchMap} from 'rxjs/operators';
import AccountStatus from "@/views/Dashboard/Account/Status.vue";
import Multiselect from 'vue-multiselect'
import * as VueGoogleMaps from "vue2-google-maps";
import axios from 'axios';
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import * as geofire from 'geofire-common'; // this use for geo queries
import { gmapApi } from "vue2-google-maps";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Clipboard } from "v-clipboard"

extend("required", required);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_FIREBASE_CONFIG_GOOGLE_API_KEY,
    libraries: "places"
  }
});
extend("minimum", {
  params: ["minimum"],
  validate: (searchRadiusMiles, {minimum})=>{
    return (parseInt(searchRadiusMiles)>=minimum);
  },
  message:'Minimum'
});
extend("maximum", {
  params: ["maximum"],
  validate: (searchRadiusMiles, {maximum})=>{
    return (parseInt(searchRadiusMiles)<=maximum);
  },
  message:'Maximum'
});
Vue.prototype.moment = moment;
Vue.prototype.numeral = numeral;
export default {
  components: {
    AccountStatus,
    Multiselect,
    GmapCluster,
  },
  methods:{
    async deleteCommodity(commodity){
      if(confirm('Are you sure you want to delete this product?')){
        this.loading=true;
        this.deleteButton = true;
        let ref = await db.collection('commodities').doc(commodity.id);
        ref.update({is_delete: 1}).then(() => {
          this.deleteButton = false;
          this.data = [];
          setTimeout(() => {
            this.loadCommodities();
          }, 7000);
        });
      }
    },

    async shareProductURL(commodity){
      // if (navigator.share) {
      //   navigator.share({
      //     title: commodity.subtype + " " + commodity.type,
      //     text: commodity.subtype + " " + commodity.type,
      //     url: window.location.origin + '/commodity/view/'+commodity.id
      //   })
      // } else {
        let URL = window.location.origin + '/commodity/view/'+commodity.id
        if(commodity.slug != undefined){
          URL = window.location.origin + '/commodity/view/' + commodity.slug
        }
        Clipboard.copy(URL).then(() => {
          this.copyLinkPopup = true;
        });
      // }
    },

    getCurrentLocation(){      
      if (navigator.geolocation) {
        this.commodityPlace = [];
        navigator.geolocation.getCurrentPosition(async(currentPosition) => {
          var data  = {
            lat: currentPosition.coords.latitude,
            lng: currentPosition.coords.longitude
          }
          await this.getCurrentAddressFrom(data.lat, data.lng);
          this.searchWithLocation();
        })
      }
    },
    async getCurrentAddressFrom(lat, long){
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      }
      try {
        var { data } = await axios.get("https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
         lat + "," + long + "&key="+ process.env.VUE_APP_FIREBASE_CONFIG_GOOGLE_API_KEY, config)
         if (data.status == "OK") {
          this.setPlaceSpecificLocation(data.results[0]);
        } else {
          console.log(data.error_message)
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    changeFilterOption(){
      this.commodityPlace = [];
      this.searchRadiusMiles = 50;
      this.positionFailed = null;
      this.maximumLimitOfMilesFailed = null;
      this.place = [];
      this.startPlace = '';
      this.destinationPlace = '';
      this.specificPlace = '';
      this.load();
      this.routesPolylinePath = [];
      this.routesCenterPoints = "";
      this.routesPointRadius = "";
    },
    setStartPlace(startPlace) {
      this.getRouteAllCoordinate =[];
      this.startPlace = startPlace.formatted_address
      var data = {
        position:{
          lat: startPlace.geometry.location.lat(),
          lng: startPlace.geometry.location.lng()
        },
      };
      this.place.push(data);
    },
    setDestinationPlace(destinationPlace){
      this.getRouteAllCoordinate =[];
      this.destinationPlace = destinationPlace.formatted_address
      var data = {
        position:{
          lat: destinationPlace.geometry.location.lat(),
          lng: destinationPlace.geometry.location.lng()
        },
      };
      this.place.push(data);
    },
    setPlaceSpecificLocation(specificPlace){
      if(this.commodityPlace.length != 0){this.commodityPlace = []}
      this.specificPlace = specificPlace.formatted_address;
      var data = {
        position:{
          lat: specificPlace.geometry.location.lat(),
          lng: specificPlace.geometry.location.lng()
        },
      };
      this.place.push(data);
    },
    async getAllPossibleCoordinates(){
        try {
            let vm = this;
            vm.getRouteAllCoordinate = [];
            let directionService = new vm.google.maps.DirectionsService();
            let request = {
              origin: this.startPlace,
              destination: this.destinationPlace,
              travelMode: 'DRIVING',
            }
           await directionService.route(request).then((response) =>{
              let getDirectionData = JSON.parse(JSON.stringify(response));
              const route =  getDirectionData.routes[0].legs[0];
              const routeDistance = route.distance.value;
              const startLocation = route.start_location;
              const endLocation = route.end_location;
              const centerPoint  = {
                lat : (startLocation.lat + endLocation.lat) / 2,
                lng : (startLocation.lng + endLocation.lng) / 2, 
              } // calculate center point between alone routes

              var legs = response.routes[0].legs;
              for (var i = 0; i < legs.length; i++) {
                var steps = legs[i].steps;
                for (var j = 0; j < steps.length; j++) {
                  var nextSegment = steps[j].path;
                  if(nextSegment.length != 2){
                    for (var k = 0; k < nextSegment.length; k++) {
                      vm.routesPolylinePath.push(nextSegment[k]);
                    }
                  }
                }
              } // draw polyline between routes

              vm.routesCenterPoints = centerPoint;
              vm.routesPointRadius = (routeDistance / 2);
              getDirectionData.routes.forEach((route) => {
                route.legs.forEach((leg) => {
                  leg.steps.forEach((step) => {
                    if(step.start_location){
                      vm.getRouteAllCoordinate.push(step.start_location);
                    }
                  })
                });
              });
            });
      } catch (error) {
        console.log(error.message);
      }
    },
    getCommodityData(promises, center){
      let vm = this;
      const radiusInM = (vm.searchRadiusMiles * 1609.34);
      this.radiusMeter = radiusInM
      Promise.all(promises).then((snapshots) => {
          const matchingDocs =[];
            for (const snap of snapshots) {
              for(const doc of snap.docs) {
                const distanceInKm = geofire.distanceBetween([doc.get('position.lat'), doc.get('position.lng')], center);
                const distanceInM = (distanceInKm) * 1000;
                if (distanceInM <= radiusInM) {
                  matchingDocs.push(doc);
                }
              }
            }
          return matchingDocs
      }).then(async(matchingDocs) => {
          for(const doc of matchingDocs) {
            const commodityId = doc.id;
            let ref = db.collection('commodities').where('id', '==', commodityId);
            if(this.selectedCategoryType != null){
              ref = ref.where('category','==',this.selectedCategoryType.id);
            }
            if(this.selectedCommodityType != null){
              ref = ref.where('type','==',this.selectedCommodityType.id);
            }
            if(this.selectedCommoditySubType != null){
              ref = ref.where('subtype','==',this.selectedCommoditySubType.id);
            }
            if(this.selectedStatus != null){
              ref = ref.where('active','==',(this.selectedStatus.id=='yes'?true:false));
            }
            if(this.selectedCertification != null){
              ref = ref.where('certification','array-contains',this.selectedCertification.id);
            }
            let ref$ = from(ref.get()).pipe(
              switchMap(commodities=>{
                if(commodities.empty) return of([]);
                return combineLatest(...commodities.docs.map(c=>{
                  return docData(db.collection('commodity_types').doc(c.data().type)).pipe(
                    map(t=>{
                      return {...c.data(),type:t.name, typeId:c.data().type }
                    })
                  )
                }))
              })).pipe(
              switchMap(commodities=>{
                  if(commodities.length==0) return of([]);
                  return combineLatest(...commodities.map(c=>{
                    return docData(db.collection('units').doc(c.unit)).pipe(
                      map(t=>{
                        return {...c,unit:t.name, unitId: c.unit}
                      })
                    )
                  }))
                })
              ).pipe(
                switchMap(commodities=>{
                  if(commodities.length==0) return of([]);
                  return combineLatest(...commodities.map(c=>{
                    return docData(db.collection('commodity_subtypes').doc(c.subtype)).pipe(
                      map(t=>{
                        return {...c,subtype:t.name, subtypeId: c.subtype}
                      })
                    )
                  }))
                })
              ).pipe(
              switchMap(commodities=>{
                  if(commodities.length==0) return of([]);
                  return combineLatest(...commodities.map(c=> {
                    return docData(db.collection('commodity_location').doc(c.id)).pipe(
                      map(l => {
                        return {...c, commodityLocation: l}
                      })
                    )
                  }))
                })
              )
              ref$.subscribe(async(commodities) => {
                  let saved = []; 
                  let ref = await db.collection('commodity_bookmarks').where('userID','==',vm.user.uid).get();
                  if(!ref.empty) ref.docs.map(d=>saved.push(d.data().commodityID));
                  let data=commodities.map(c=>{
                    let totalView = [];
                    vm.totalViewCount(c.id).then((result) => {
                        totalView.push(result);
                    });
                    return {...c,bookmarked:(saved.indexOf(c.id)>-1?true:false), totalCommodityView: totalView}
                  });
                  Array.from(Array(data.length).keys()).map(x => {
                    if(data[x].is_delete == 0 || data[x].is_delete == undefined){  
                      if((data[x].commodityLocation.position != undefined) && (data[x].placePosition != undefined)){
                        vm.data.push(data[x]);
                      }
                    }
                  })
              });
          }
        });
    },
    async searchWithLocation() {
      var vm = this;
      vm.loading = true;
      vm.data = [];
      if(vm.commodityPlace.length == 0){
        vm.commodityPlace = vm.place;
        vm.place = []; 
      }
      if((this.destinationPlace != "") && (this.startPlace != "") || (this.specificPlace != "") && (vm.searchRadiusMiles <= this.maximumLimitOfMiles)){
          const radiusInM = (vm.searchRadiusMiles * 1609.34);
          this.radiusMeter = radiusInM;
          vm.positionFailed=null;
          vm.maximumLimitOfMilesFailed = null;
          const promises = [];
              if(vm.filterOption == false){
                  vm.routesCenterPoints = "";
                  vm.routesPolylinePath = [];
                  await this.getAllPossibleCoordinates();
              }
              if(vm.getRouteAllCoordinate.length != 0 && vm.filterOption == false){
                  vm.getRouteAllCoordinate.forEach((route) => {
                    const center = [route.lat, route.lng];   
                    const bounds= geofire.geohashQueryBounds(center, radiusInM); 
                    for(const b of bounds){
                      var q = db.collection('commodity_location');
                      q = q.orderBy('geohash').startAt(b[0]).endAt(b[1])
                      promises.push(q.get());
                    }
                    this.getCommodityData(promises, center);
                  });
              } else {
                vm.commodityPlace.forEach((place) => {
                  const center = [place.position.lat, place.position.lng];    
                  const bounds= geofire.geohashQueryBounds(center, radiusInM);
                  for(const b of bounds){
                    const q = db.collection('commodity_location').orderBy('geohash').startAt(b[0]).endAt(b[1]);
                    promises.push(q.get());
                  }
                  this.getCommodityData(promises, center);
                });
              }
            vm.getRouteAllCoordinate = [];
            if(vm.data.length <= 0 ){
              setTimeout(() => {
                vm.loading = false;
                if(vm.data.length > 0){
                  vm.setMapsZoomLevel(); 
                }                                   
              }, 4000);
            }
            if(vm.data.length == 0){
              setTimeout(() => {
                vm.loading = false;   
              }, 4000);
            }
      } else {
        if(!(vm.searchRadiusMiles <= this.maximumLimitOfMiles)){
            vm.maximumLimitOfMilesFailed = true
        }
        vm.positionFailed = true
        vm.loading = false
      }
    },
    async setMapsZoomLevel(){
      let vm = this  
        var bounds = new vm.google.maps.LatLngBounds();
        for(let d of this.data){
          bounds.extend(d.placePosition);
        }
        setTimeout(function(){
            for(let ref in vm.$refs){
              if(ref == "commodityMap"){
                vm.$refs[ref].fitBounds(bounds);
              }
            } 
        }, 1000)
      if(this.data.length > 1){
        this.zoom = 10;
      } else if (this.data.length >= 6 && this.data.length <= 2){
        this.zoom = 8;
      } else if (this.data.length >= 10 && this.data.length <= 6){
        this.zoom = 6;
      } else {
        this.zoom = 4;
      }
    },
    openCommodity(commodityId){
      this.openCommodityID = commodityId
    },
    viewCommodity(commodityId){
      this.$router.push('/commodity/view/'+commodityId)
    },
    updateCommodityLocationGeoHash(data){
      Array.from(Array(data.length).keys()).map(async(x) => {
          if(data[x].commodityLocation.geohash == undefined){
            const lat = data[x].commodityLocation.position.lat;
            const lng = data[x].commodityLocation.position.lng;
            const hash = geofire.geohashForLocation([lat, lng]);
            const ref = await db.collection('commodity_location').doc(data[x].id);
            ref.update({
              geohash:  hash
            });          
          }
          if(data[x].geohash == undefined){
            const lat  = data[x].commodityLocation.position.lat;
            const lng  = data[x].commodityLocation.position.lng;
            const hash = geofire.geohashForLocation([lat, lng]);
            const ref  = await db.collection('commodities').doc(data[x].id);
            ref.update({
              geohash:  hash,
              placePosition : {
                lat: lat,
                lng: lng
              },
            })
          }
      });
    },
    loadCommodities(){
      let vm = this;
      vm.loading=true;
      vm.data = [];
      let ref = db.collection('commodities');
      ref = ref.where('is_delete', '==', 0)
      if(this.selectedStatus != null){
        ref = ref.where('active','==',(this.selectedStatus.id=='yes'?true:false));
      }

      if(this.selectedCategoryType != null){
        ref = ref.where('category','==',this.selectedCategoryType.id);
      }

      if(this.selectedCommodityType != null){
        ref = ref.where('type','==',this.selectedCommodityType.id);
      }
      if(this.selectedCommoditySubType != null){
        ref = ref.where('subtype','==',this.selectedCommoditySubType.id);
      }
      if(this.selectedCertification != null){
        ref = ref.where('certification','array-contains',this.selectedCertification.id);
      }
    
      let ref$ = from(ref.get()).pipe(
        switchMap(commodities=>{
          if(commodities.empty) return of([]);
          return combineLatest(...commodities.docs.map(c=>{
            return docData(db.collection('commodity_types').doc(c.data().type)).pipe(
              map(t=>{
                return {...c.data(),type:t.name, typeId: c.data().type}
              })
            )
          }))
        })
      ).pipe(
        switchMap(commodities=>{
          if(commodities.length==0) return of([]);
          return combineLatest(...commodities.map(c=>{
            return docData(db.collection('units').doc(c.unit)).pipe(
              map(t=>{
                return {...c,unit:t.name}
              })
            )
          }))
        })
      ).pipe(
        switchMap(commodities=>{
          if(commodities.length==0) return of([]);
          return combineLatest(...commodities.map(c=>{
            return docData(db.collection('commodity_subtypes').doc(c.subtype)).pipe(
              map(t=>{
                return {...c,subtype:t.name, subtypeId: c.subtype}
              })
            )
          }))
        })
      ).pipe(
        switchMap(commodities=>{
          if(commodities.length==0) return of([]);
          return combineLatest(...commodities.map(c=> {
            return docData(db.collection('commodity_location').doc(c.id)).pipe(
              map(l => {
                return {...c, commodityLocation: l}
              })
            )
          }))
        })
      );
      ref$.subscribe(async(commodities)=>{
        let saved = []; 
        vm.data = [];
        let ref = await db.collection('commodity_bookmarks').where('userID','==',vm.user.uid).get();
        if(!ref.empty) ref.docs.map(d=>saved.push(d.data().commodityID));
        let data=commodities.map((c)=>{
          let totalView = [];
          this.totalViewCount(c.id).then((result) => {
              totalView.push(result);
          });
          return {...c,bookmarked:(saved.indexOf(c.id)>-1?true:false), totalCommodityView: totalView}
        });
        if(vm.user.role.admin){
          await vm.updateCommodityLocationGeoHash(data);
        }

        if(vm.user.role.admin){
          Array.from(Array(data.length).keys()).map(async(x) => {
              if(data[x].is_delete != 1 && data[x].slug == undefined){
                  await vm.updateProductSlugInProducts(data[x].subtypeId, data[x].typeId, data[x].id); 
              }
          });
        }

        Array.from(Array(data.length).keys()).map(x => {
          if(data[x].is_delete != 1 || data[x].is_delete == undefined){
            if((data[x].commodityLocation.position != undefined) && (data[x].placePosition != undefined)){
              vm.data.push(data[x]);
            }
          }
        })
        vm.loading=false;
          if(vm.data.length > 0){
            vm.setMapsZoomLevel(); 
          }
      });
    },

    generateRandomNumber(){
      return Math.floor(Math.random() * (99 - 10 + 1) + 10) ;
    }, 
    async updateProductSlugInProducts(subtypeId, typeId, productId){
      const vm = this;
      let tempSlug = null; 

      let ref = await db.collection('commodity_subtypes').doc(subtypeId).get();
        if(!ref.empty) var subType = {...ref.data()}.name;

        ref = await db.collection('commodity_types').doc(typeId).get();
        if(!ref.empty) var type = {...ref.data()}.name;

        let productRef = await db.collection('commodities')
                                 .where('type', '==', typeId)
                                 .where('subtype', '==', subtypeId).get();

        if(!productRef.empty){
          let totalExistingProduct = productRef.docs.length + vm.generateRandomNumber();
          tempSlug = (subType + "-" + type + "-" + totalExistingProduct).trim();
        } else {
          tempSlug = (subType + "-" + type).trim();
        }                      

      let productSlug = tempSlug.toLowerCase().replace(/\s+/g,'-');
      productSlug = productSlug.replace("/", "-");
    
      const updateProductRef = await db.collection('commodities').doc(productId);
      updateProductRef.update({
          slug:  productSlug
      });       
    },

    async totalViewCount(id){
      let totalViewCountRef = await db.collection('commodity_bookmarks').where('commodityID','==',id).get();
      return totalViewCountRef.size;
    },
    choose(id){
      this.$router.push('/commodity/view/'+id);
    },
    async bookmark(id){
      let vm = this;
      vm.data.map(d=>{
        if(d.id==id) d.bookmarked=!d.bookmarked;
      });
      let ref = await db.collection('commodity_bookmarks').where('commodityID','==',id).where('userID','==',vm.user.uid).get();
      if(!ref.empty) ref.docs[0].ref.delete();
      else await db.collection('commodity_bookmarks').add({
        commodityID:id,
        userID:vm.user.uid,
        created:firebase.firestore.FieldValue.serverTimestamp()
      });
    },
    formatNumeral(amount,format){
      return numeral(amount).format(format);
    },
    primaryImage(images){
      let arr = images.filter(i=>i.primary);
      if(arr.length==0) return images[0];
      else return arr[0];
    },

    async loadTypes(){
      let vm = this;
      let filters = store.state.filters;
      filters['commodity'] = [];
      vm.selectedCommodityType=null;
      if(vm.selectedCategoryType){
        let ref = await db.collection('commodity_types').where('category','==',vm.selectedCategoryType.id).orderBy('name','asc').get();
        ref.forEach(t=>filters['commodity'].push({...t.data(),type:'commodity',id:t.id,checked:false,hidden:false}));
      }
      store.commit('updateFilters',filters);
    },

    async loadSubTypes(){
      let vm = this;
      let filters = store.state.filters;
      filters['subtype']=[];
      vm.selectedCommoditySubType=null;
      if(vm.selectedCommodityType){
        let ref = await db.collection('commodity_subtypes').where('type','==',vm.selectedCommodityType.id).orderBy('name','asc').get();
        ref.forEach(t=>filters['subtype'].push({...t.data(),type:'subtype',id:t.id,checked:false,hidden:false}));
      }
      store.commit('updateFilters',filters);
    },
    clearFilters(){
      this.selectedCommoditySubType=null;
      this.selectedCommodityType=null;
      this.selectedCategoryType=null;
      if(this.user.role.admin) this.selectedStatus=null;
      this.selectedCertification=null;
      this.loading = true;
      this.data = [];
    },
    clearLocationFilters(){
      this.commodityPlace = [];
      this.searchRadiusMiles = 50;
      this.specificPlace = '';
      this.startPlace = '';
      this.destinationPlace = '';
      this.data = [];
      this.place = [];
      this.getRouteAllCoordinate = [];
      this.load();
      this.positionFailed=null;
      this.maximumLimitOfMilesFailed=null;
      this.routesPolylinePath = [];
      this.routesCenterPoints = "";
      this.routesPointRadius = "";
    },
    getUniqueValueFromArray(items){      
      return [
        ...new Map(
            items.map((item) => [item['id'], item])
          ).values()
      ]
    },
    async load(){
      let vm = this;
      vm.mounting=true;
      if(localStorage.selectedCategoryType){
        vm.selectedCategoryType=JSON.parse(localStorage.selectedCategoryType);
        await vm.loadTypes();
      }
      if(localStorage.selectedCommodityType){
        vm.selectedCommodityType=JSON.parse(localStorage.selectedCommodityType);
        await vm.loadSubTypes();
      }
      if(localStorage.selectedCommoditySubType) vm.selectedCommoditySubType = JSON.parse(localStorage.selectedCommoditySubType);
      if(localStorage.selectedCertification) vm.selectedCertification = JSON.parse(localStorage.selectedCertification);
      vm.loadCommodities();
      bus.$emit('prepareTitle',null);
      vm.mounting=false;
    }
  },
  computed:{
    ...mapState(['user','filters']),
    hasFilters(){
      return this.selectedCategoryType || this.selectedCommoditySubType || this.selectedCommodityType || (this.user.role.admin&&this.selectedStatus) || this.selectedCertification;
    },
    google: gmapApi,
  },
  watch:{
    selectedStatus(){
      if(!this.mounting){
        (this.commodityPlace.length > 0) ? this.searchWithLocation() : this.loadCommodities();
      } 
    },
    async selectedCategoryType(){
      let vm = this;
      localStorage.selectedCategoryType=JSON.stringify(vm.selectedCategoryType);
      if(!vm.mounting){
        await vm.loadTypes();
        (this.commodityPlace.length > 0) ? vm.searchWithLocation() : vm.loadCommodities();
      }
    },
    async selectedCommodityType(){
      let vm = this;
      localStorage.selectedCommodityType=JSON.stringify(vm.selectedCommodityType);
      if(!vm.mounting) {
        await vm.loadSubTypes();
        (this.commodityPlace.length > 0) ? vm.searchWithLocation() : vm.loadCommodities();
      } 
    },
    selectedCommoditySubType(){
      localStorage.selectedCommoditySubType=JSON.stringify(this.selectedCommoditySubType);
      if(!this.mounting) {
        (this.commodityPlace.length > 0) ? this.searchWithLocation() : this.loadCommodities();
      }
      
    },
    selectedCertification(){
      localStorage.selectedCertification=JSON.stringify(this.selectedCertification);
      if(!this.mounting) {
        (this.commodityPlace.length > 0) ? this.searchWithLocation() : this.loadCommodities();
      } 
    },  
  },
  data() {
    return {
      data: [],
      loading: true,
      mounting: false,
      selectedCategoryType:null,
      selectedCommodityType:null,
      selectedCommoditySubType:null,
      selectedCertification:null,
      selectedStatus:{id:'yes',name:'Yes'},
      deleteButton: false,
      commodityPlace: [],
      radiusMeter: '',
      place: [],
      getRouteAllCoordinate: [],
      specificPlace: '',
      startPlace: '',
      destinationPlace: '',
      openCommodityID: null,
      searchRadiusMiles: 50,
      currentLocation: '',
      viewOption: true,
      filterOption: true,
      zoom: 12,
      maximumLimitOfMiles: null,
      positionFailed: null,
      maximumLimitOfMilesFailed: null, 
      routesCenterPoints: '',
      routesPointRadius: '',
      routesPolylinePath: [],
      copyLinkPopup: false,
      statusText: '',
      options: [
        { text: 'Map', value: true },
        { text: 'List', value: false}
      ],
      filterOptions: [
        { text: 'Location', value: true },
        { text: 'Along Route', value: false}
      ],
    }
  },
  mounted(){
    if(store.state.limitOfMiles != undefined){
      this.maximumLimitOfMiles =  JSON.parse(JSON.stringify(store.state.limitOfMiles.limitMiles));
    } else {
      this.maximumLimitOfMiles = 100;
    }
    this.load();
  },
};
</script>
<style>
.table-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.card.card-commodity {
  height: calc(100% - 20px);
}

.buttonMargin{ 
    margin-top: 30px !important;
}

.card-commodity .card-header {
    background-color: unset !important;
}
.fixed-bottom {
  position: absolute;
  bottom: 40px;
  margin: 15px;
  width: calc(100% - 30px );
}
.fixed-delete-bottom {
  position: absolute;
  bottom: 10px;
  margin: 0px;
  width: calc(100% - 30px );
}

.fixed-share-bottom{
  position: absolute;
  bottom: 40px;
  margin: 60px;
  margin-left: 0px;
  width: calc(100% - 30px);
}

.fixed-card-body {
  padding-bottom: 100px !important
}

.card-img-top{
    width: 100%;
    height: 10vw;
    object-fit: cover;
}

img.viewProductImage { display: block; margin: 0 auto 10px; max-height: 140px; width: 100%; height: auto;}
@media (max-width:1620px){
  .card-img-top {
    height: 18vw;
}
}

@media (max-width:1024px){
  .card-img-top {
    height: 16vw;
}
}
@media (max-width:767px){
  .card-img-top {
    height: unset;
}
img.viewProductImage { max-width: 100%; }
}
.text-color-red {
  color:red;
  font-size: 15px;
}
vdp-datepicker input[type="text"], .pac-target-input{
    border: 1px solid #a8a8a8 !important  ;
    border-radius: 3px !important;
    
}
.mapOptions .btn-secondary:not(:disabled):not(.disabled).active{
 background : #7ec313;
}
.filterOption .btn-secondary:not(:disabled):not(.disabled).active{
  background : #7ec313;
}

.commodityShowMap .gm-style-iw-d{
  max-height: 390px !important;
}
.commodityShowMap .gm-style .gm-style-iw-c{
  padding: 16px;
  max-height: 400px !important;
  max-width: 300px !important;
}
.commodityShowMap  .gm-ui-hover-effect{
    top: -3px !important;
    right: -3px !important;
}
.commodityShowMap  .gm-ui-hover-effect:focus {
    outline: 0px dotted;
    outline: 0x auto -webkit-focus-ring-color;
}

@media (max-width: 767px) {
  .marketplace-filters div {
      margin-bottom: 10px;
  } 
}


</style>