<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <b-card class="rounded border-2">
                    <b-card-header>
                        <h5 class="m-0">All Messages</h5>
                    </b-card-header>
                    <b-card-body>
                        <b-list-group v-if="!deleting">
                            <b-list-group-item v-for="(m) in timeline" :key="m.id"
                                class="d-block d-md-flex align-items-center text-center text-md-left">
                                <div class="text-center text-md-left w-100 pl-0 pl-md-4 pr-0 pr-md-4">
                                    <!-- <span :class="'badge badge-' + (variant(m.type))">{{(m.type=='message'?m.from:m.type)}}</span> -->
                                    <div slot="header" class="d-flex flex-row justify-content-between items-center align-items-center gap-3">
                                        <span :class="'badge badge-' + (variant(m.type))">From: {{(m.type=='message'?m.from:m.type)}}</span>
                                        <span :class="'badge badge-' + (variant(m.type)) ">To: {{ (m.type=='message'?m.showTo:m.type) }}</span>
                                        <span class="badge badge-info">Commodity Name: {{ m.showCommodityType }} {{ m.showCommoditySubtype }}</span>
                                        <small class="w-100 text-muted text-right"><i class="ti-time"></i> {{formatDate(m.created)}}</small>
                                    </div>
                                    <div class="d-flex flex-row justify-content-between">
                                        <div class="w-25" v-if="m.title"><strong>{{ m.title }}</strong></div>
                                        <div class="w-25 text-muted" v-if="m.text">{{ m.text }}</div>
                                        <div class="d-flex flex-column w-25">
                                            <div v-for="(file, index) in m.messageAttachment" :key="index">
                                                <a @click="viewFile(file)" class="text-info mr-2" title="view">
                                                    <span class="fa fa-external-link text-black add-pointer"></span>
                                                </a>
                                                <strong>
                                                    <em class="text-muted" v-if="file.fileNameShow">{{ file.fileNameShow
                                                    }}</em>
                                                    <em class="text-muted" v-else>{{ file.name }}</em>
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="m.id == null ? 'display-none':''" class="d-flex justify-content-end">
                                        <!-- <button class="btn btn-danger float-start" :disabled="deleting" @click="deleteMessage(m)"><span class="fa fa-trash add-pointer mr-2"></span>Delete</button> -->
                                        <div v-if="m.is_hide">
                                            <button class="btn btn-primary float-start" :disabled="updateStatus" @click.prevent="showMessages(m)"><span class="fa fa-eye add-pointer mr-2"></span>Show</button>
                                        </div>
                                        <div v-else>
                                            <button class="btn btn-danger float-start" :disabled="updateStatus" @click.prevent="hideMessage(m)"><span class="fa fa-eye-slash add-pointer mr-2"></span>Hide</button>
                                        </div>
                                    </div>
                                </div>
                            </b-list-group-item>
                            <b-list-group-item @click="getMessages" class="text-center add-pointer" variant="success">
                                <span class="fa fa-plus-circle mr-2"><strong class="ml-2">Show More</strong></span>
                            </b-list-group-item>
                        </b-list-group>
                    </b-card-body>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '@/firebase';
import { mapState } from 'vuex'
import { collectionData, docData } from 'rxfire/firestore';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import moment from 'moment';
import { firestorage } from '@/firebase';

export default {
    computed: {
        ...mapState(['user'])
    },
    data() {
        return {
            timeline: [],
            allMessagesLoaded: true,
            lastMessageDoc: null,
            deleting: false,
            updateStatus: false,
        }
    },
    mounted() {
        if(!this.user.role.admin){
            this.$router.replace("/not/access")
        }
        this.getMessages();
    },
    methods: {
        getMessages(afterDeleteMessage = false) {
            let vm = this;
            if (!vm.user.role.admin) vm.newMessage.to = ['admin'];
            vm.loading = true;
            let ref = db.collection('messages').orderBy('created', 'desc').limit(10)
            if (vm.lastMessageDoc) ref = ref.startAfter(vm.lastMessageDoc);
            vm.getMessageForDoc(ref);
            collectionData(ref, 'id').pipe(
                switchMap(inbox => {
                    if(inbox.length == 0||inbox.length < 10) vm.allMessagesLoaded=false;
                    if(inbox.length == 0) return of([]);
                    return combineLatest(...inbox.map(m => {
                        if (m.id == undefined) {
                            m.id = null;
                        }
                        return docData(db.collection('users').doc(m.from)).pipe(
                            map(u => {
                                let from = '';
                                if (u.business != undefined) {
                                    from = (u.business.type == 'admin' ? 'NGF Global' : (vm.user.role.admin ? u.about.first + ' ' + u.about.last : (vm.user.uid == m.from ? 'me' : u.business.type)))
                                }
                                return { ...m, from: from }
                            })
                        )
                    }))
                })
            ).pipe(
                switchMap(inbox => {
                    return combineLatest(...inbox.map(m => {
                        if (m.id == undefined) {
                            m.id = null;
                        }
                        return docData(db.collection('users').doc(m.to)).pipe(
                            map(u => {
                                let showTo = '';
                                if(m.to == "admin"){
                                    showTo = 'NGF Global'
                                }
                                if (u.business != undefined) {
                                    showTo = (u.business.type == 'admin' ? 'NGF Global' : (vm.user.role.admin ? u.about.first + ' ' + u.about.last : (vm.user.uid == m.to ? 'me' : u.business.type)))
                                }
                                return { ...m, showTo: showTo }
                            })
                        )
                    }))
                })
            ).pipe(
                switchMap(inbox => {
                    return combineLatest(...inbox.map(m => {
                        if (m.id == undefined) {
                            m.id = null;
                        }
                        return docData(db.collection('commodities').doc(m.commodityID)).pipe(
                            map(c => {
                                return { ...m, commodityType: c.type, commoditySubType: c.subtype }
                            })
                        )
                    }))
                })
            ).pipe(
                switchMap(inbox => {
                    return combineLatest(...inbox.map(m => {
                        if (m.id == undefined) {
                            m.id = null;
                        }
                        return docData(db.collection('commodity_types').doc(m.commodityType)).pipe(
                            map(t => {
                                let showCommodityType = ''
                                if(t.name != undefined){
                                    showCommodityType = t.name;
                                }
                                return { ...m, showCommodityType: showCommodityType}
                            })
                        )
                    }))
                })
            ).pipe(
                switchMap(inbox => {
                    return combineLatest(...inbox.map(m => {
                        if (m.id == undefined) {
                            m.id = null;
                        }
                        return docData(db.collection('commodity_subtypes').doc(m.commoditySubType)).pipe(
                            map(s => {
                                let showCommoditySubtype = ''
                                if(s.name != undefined){
                                    showCommoditySubtype = s.name;
                                }
                                return { ...m, showCommoditySubtype: showCommoditySubtype}
                            })
                        )
                    }))
                })
            ).subscribe(async(inbox) => {
                if(afterDeleteMessage == true){
                    vm.timeline = [];    
                }
                inbox.map(async(m) =>{
                    let getAttachment = await vm.getAllMessageAttachments(m.id);
                    vm.timeline.push({ ...m,messageAttachment: getAttachment })
                });
            });
        },
        getAllMessageAttachments(messageId) {
            let getAttachment = [];
            if (messageId != '' && messageId != undefined) {
                let getAttachmentRef = db.collection('message_attachments');
                getAttachmentRef = getAttachmentRef.where('messageId', '==', messageId);
                getAttachmentRef.get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        getAttachment.push(doc.data())
                    });
                });
                return getAttachment;
            }
        },
        async hideMessage(message){
            if(confirm('Are you sure you want to  hide this message?')){
                this.updateStatus = true;
                let messageRef = await db.collection('messages').doc(message.id)
                messageRef.get().then(async(doc) => {
                    await this.updateMessageHideStatus(doc.data(), true)
                });
                setTimeout(() => {
                    this.updateStatus = false;
                    this.getMessages(true);
                }, 1000);
            }
        },
        async showMessages(message){
            if(confirm('Are you sure you want to show this message?')){
                this.updateStatus = true;
                let messageRef = await db.collection('messages').doc(message.id)
                messageRef.get().then(async(doc) => {
                await this.updateMessageHideStatus(doc.data(), false)
                });
                setTimeout(() => {
                    this.updateStatus = false;
                    this.getMessages(true);
                }, 1000);
            }
        },
        async updateMessageHideStatus(message, messageStatus){
            let messageRef2 = await db.collection('messages')
            messageRef2 = messageRef2.where('text', '==', message.text)
            messageRef2 = messageRef2.where('title', '==', message.title)
            messageRef2 = messageRef2.where('commodityID', '==', message.commodityID)
            messageRef2 = messageRef2.where('orderID', '==', message.orderID)
            messageRef2 = messageRef2.where('from', '==', message.from)
            messageRef2.get().then((querySnapshot) => {
                querySnapshot.forEach(async(doc) => {
                    doc.ref.update({is_hide: messageStatus})
                });
            });
        },
        formatDate(d) {
            if (d) {
                d = d.toDate();
                if (moment().diff(d, 'minutes') < 1) return moment().diff(d, 'seconds') + ' secs ago';
                if (moment().diff(d, 'hours') < 1) return moment().diff(d, 'minutes') + ' mins ago';
                if (moment().diff(d, 'days') < 1) return moment().diff(d, 'hours') + ' hrs ago';
                return moment(d).format('ll');
            } else {
                return '';
            }
        },
        deleteMessage(m) {
            if (confirm('Are you sure you want to delete this message?')) {
                this.deleting = true;
                this.deleteAttachments(m);
                let messageRef = db.collection('messages').doc(m.id)
                messageRef.delete().then(() => {
                    this.lastMessageDoc = null;
                    this.deleting = false;
                    this.timeline = [];
                    this.getMessages(true);
                })
            }
        },
        async deleteAttachments(message) {
            if (message != "") {
                let messageAttachmentRef = await db.collection('message_attachments');
                messageAttachmentRef = messageAttachmentRef.where('messageId', '==', message.id);
                messageAttachmentRef.onSnapshot((querySnapshot) => {
                    if (querySnapshot.docs.length != 0) {
                        querySnapshot.forEach(async (doc) => {
                            let storageRef = await firestorage.ref(doc.data().fullPath);
                            storageRef.delete();
                            doc.ref.delete();
                        });
                    }
                });
            }
        },
        async viewFile(file) {
            if (file != '' && file != undefined) {
                let attachmentRef = await firestorage.ref(file.fullPath);
                attachmentRef.getDownloadURL().then((url) => {
                    window.open(url, '_blank');
                }).catch(() => {
                    return false;
                });
            }
        },
        getMessageForDoc(ref) {
            ref.onSnapshot((querySnapshot) => {
                this.getAttachLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1])
            });
        },
        getAttachLastDoc(doc) {
            this.lastMessageDoc = doc;
        },
        variant(type) {
            return (type == 'shipping' ? 'warning' : (type == 'update' ? 'success' : (type == 'alert' ? 'danger' : 'info')));
        },
        icon(type) {
            return (type == 'shipping' ? 'fa-truck' : (type == 'update' ? 'fa-check' : (type == 'alert' ? 'fa-exclamation-triangle' : 'fa-comments')));
        }
    }
}
</script>
<style>
.add-pointer {
    cursor: pointer;
}

.display-none {
    display: none !important;
}

.gap-3{
    column-gap: 5px;
}
</style>